import React, { useState } from 'react';
import { useRegister } from '../../hooks/use-register';
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { Helmet } from 'react-helmet-async';
import InputComponent from '../../components/input/input.component';
import ErrorValidateComponent from '../../components/error-validate/error-validate.component';
import { Link } from 'react-router-dom';
import { routePaths } from '../../routes/routes-path';
import ButtonPrimary from '../../components/button-refactor/button-primary';
import Alert from '../../components/alert/alert';
import { Modal } from 'antd';

const registerSchema = yup.object().shape({
  email: yup.string().email('Email không hợp lệ').required('Email không được bỏ trống'),
  username: yup.string().required('Tên đăng nhập không được bỏ trống'),
  fullName: yup.string().required('Tên không được bỏ trống'),
  password: yup.string().required('Mật khẩu không được bỏ trống').min(6, 'Mật khẩu phải có ít nhất 6 ký tự'),
  confirmPassword: yup
    .string()
    .required('Nhập lại mật khẩu không khớp')
    .oneOf([yup.ref('password'), ''], 'Nhập lại mật khẩu không khớp'),
  code: yup.string().required('Mã giới thiệu không được bỏ trống'),
});

export interface IRegisterFormValues {
  email: string;
  fullName: string;
  username: string;
  password: string;
  code: string;
  confirmPassword: string;
}

export const CreateUserModal = () => {
  const [registerSuccess, setRegisterSuccess] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const onRegisterSuccess = () => {
    setRegisterSuccess(true);
  };

  const { handleRegister, loading } = useRegister(onRegisterSuccess);

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<IRegisterFormValues>({
    mode: 'all',
    resolver: yupResolver(registerSchema),
  });

  const onSubmit: SubmitHandler<IRegisterFormValues> = async (formValues) => {
    await handleRegister(formValues);
  };

  return (
    <>
      <div className="flex justify-center items-center">
        <ButtonPrimary type="button" className="w-full max-w-md justify-center" onClick={() => setIsModalOpen(true)}>
          Tạo người dùng
        </ButtonPrimary>
      </div>

      <Modal
        title="  Tạo người dùng"
        open={isModalOpen}
        width={600}
        footer={null}
        onCancel={() => setIsModalOpen(false)}
      >
        <div className="toto-register">
          <div className="w-full">
            <form className="mb-4" onSubmit={handleSubmit(onSubmit)}>
              <div className="mb-4">
                <label className="block font-medium text-sm mb-2 " htmlFor="email">
                  Email
                </label>

                <InputComponent register={register('email')} type="email" id="email" />
                <ErrorValidateComponent visible={!!errors.email}>{errors.email?.message}</ErrorValidateComponent>
              </div>
              <div className="mb-4">
                <label className="block font-medium text-sm mb-2 " htmlFor="fullName">
                  Họ và Tên
                </label>
                <InputComponent register={register('fullName')} id="fullName" />
                <ErrorValidateComponent visible={!!errors.fullName}>{errors.fullName?.message}</ErrorValidateComponent>
              </div>
              <div className="mb-4">
                <label className="block font-medium text-sm mb-2 " htmlFor="username">
                  Tên đăng nhập
                </label>
                <InputComponent register={register('username')} id="username" />
                <ErrorValidateComponent visible={!!errors.username}>{errors.username?.message}</ErrorValidateComponent>
              </div>
              <div className="mb-4">
                <label className="block font-medium text-sm mb-2 " htmlFor="password">
                  Mật khẩu
                </label>
                <InputComponent register={register('password')} id="password" type="password" />
                <ErrorValidateComponent visible={!!errors.password}>{errors.password?.message}</ErrorValidateComponent>
              </div>
              <div className="mb-4">
                <label className="block font-medium text-sm mb-2 " htmlFor="confirmPassword">
                  Nhập lại mật khẩu
                </label>
                <InputComponent register={register('confirmPassword')} id="confirmPassword" type="password" />
                <ErrorValidateComponent visible={!!errors.confirmPassword}>
                  {errors.confirmPassword?.message}
                </ErrorValidateComponent>
              </div>
              <div className="mb-4">
                <label className="block font-medium text-sm mb-2 " htmlFor="code">
                  Mã giới thiệu
                </label>
                <InputComponent register={register('code')} id="code" />
                <ErrorValidateComponent visible={!!errors.code}>{errors.code?.message}</ErrorValidateComponent>
              </div>

              <Alert visible={registerSuccess} type="success" classNames="mb-4">
                Chúng tôi vừa gửi mail xác nhận đến địa chỉ mail của bạn.
              </Alert>

              <ButtonPrimary
                type="submit"
                className="w-full justify-center"
                loading={loading}
                disabled={loading}
                onClick={() => {
                  setRegisterSuccess(false);
                }}
              >
                Tạo
              </ButtonPrimary>
            </form>
          </div>
        </div>
      </Modal>
    </>
  );
};
