import React from 'react';
import Avatar from '../../../components/avatar/avatar.component';
import ButtonPrimary from '../../../components/button-refactor/button-primary';
import { datingService } from '../../../services/dating.service';
import { IConfirmMatchRequest } from '../../../models/dating.model';
import { toast } from 'react-hot-toast';

const example = [
  {
    requestId: '6a50810d-d45b-4c34-a1a7-e33cd8b78363',
    senderProfileId: 'fc93be8b-c095-4a9b-a0c8-b9000c2dd86c',
    createdAt: '2024-11-09T07:41:29.986849+07:00',
    nickname: 'test',
    avatar:
      'https://www.dropbox.com/scl/fi/xczbko0tbof76lp7bo9pq/vl5g3cep.ty3.jpg?rlkey=4o1eulms85sqj4bfkstb74dkm&raw=1',
    age: 23,
  },
  {
    requestId: '740e609f-99d8-473e-9e01-206cb9cedc60',
    senderProfileId: '1eeac769-7fd5-45db-b468-6f333ebdf1cb',
    createdAt: '2024-10-24T22:20:38.863773+07:00',
    nickname: 'Hieu',
    avatar: null,
    age: 25,
  },
];

interface IMatchRequestsModel {
  requestId: string;
  senderProfileId: string;
  createdAt: string;
  nickname: string;
  avatar: string;
  age: number;
}

const Requests = ({ matchRequests, refetch }: { matchRequests: IMatchRequestsModel[]; refetch: () => void }) => {
  const onConfirmMatchRequest = async (value: any) => {
    const payload: IConfirmMatchRequest = {
      requestId: value.requestId,
      isAccepted: true,
    };
    await datingService.confirmMatchRequest(payload);
    toast.success('Chấp nhận kết nối !');
    refetch();
  };

  return (
    <div className="flex flex-col gap-4 mt-8">
      <section className="bg-white rounded-xl shadow-sm text-sm font-medium border1 dark:bg-dark2 p-4 min-h-96">
        <div>
          <h3>Danh sách đang chờ</h3>
          <div className="grid grid-cols-2 gap-4 mt-4">
            {matchRequests.map((item) => (
              <div className="box dark:bg-dark2 py-2 px-2" key={item.requestId}>
                <div className="flex items-center">
                  <div className="mr-2">
                    <div className="border-4 border-white rounded-full">
                      <Avatar avatar={item.avatar || ''} label={item.nickname || ''} size="md" />
                    </div>
                  </div>
                  <div>
                    <div className="font-bold text-black dark:text-white">{item.nickname}</div>
                    <div className="text-gray-500 dark:text-gray-400">{item.age} tuổi</div>
                  </div>
                  <div className="ml-auto">
                    <ButtonPrimary className="whitespace-nowrap" onClick={() => onConfirmMatchRequest(item)}>
                      Chấp nhận
                    </ButtonPrimary>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Requests;
