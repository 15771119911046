import { API } from '..';
import { IListCreatorModel } from '../../models';

export const fetchUsersApi = (page = 1) => {
  return new Promise<any>((resolve, reject) => {
    API.post('/v1/user-service/admin/profile/search', {
      email: 'string',
      fullName: 'string',
      dateFrom: '2024-11-01T16:28:25.056Z',
      dateTo: '2024-11-01T16:28:25.056Z',
      status: 0,
      pageNumber: page,
      pageSize: 10,
    })
      .then((res) => {
        resolve(res);
      })
      .catch(reject);
  });
};
export const blockUser = (userId) => {
  return new Promise<any>((resolve, reject) => {
    API.put(`/v1/user-service/admin/profile/block/${userId}`)
      .then((res) => {
        resolve(res);
      })
      .catch(reject);
  });
};
export const confirmCreator = (userId) => {
  return new Promise<any>((resolve, reject) => {
    API.post(`/v1/user-service/admin/profile/confirm-creator/${userId}`)
      .then((res) => {
        resolve(res);
      })
      .catch(reject);
  });
};
export const deleteUser = (userId) => {
  return new Promise<any>((resolve, reject) => {
    API.delete(`/v1/user-service/admin/profile/${userId}`)
      .then((res) => {
        resolve(res);
      })
      .catch(reject);
  });
};
export const toggleChangeReciveDonateAPI = async (id: string) => {
  return new Promise<any>((resolve, reject) => {
    API.put(`/v1/user-service/admin/profile/togglechangerecivedonate/${id}`)
      .then((res) => {
        resolve(res);
      })
      .catch(reject);
  });
};
