/* eslint-disable react/react-in-jsx-scope */
import { useEffect, useMemo, useState } from 'react';
import { ModalProps } from './modal.create.post.type';
import { fetchPostCategoriesApi, getDetailPost } from '../../../api';
import { useUserProfile } from '../../../hooks';
import DialogComponent from '../../../components/dialog/dialog.component';
import TextareaAutosize from 'react-textarea-autosize';
import Select, { IOption } from '../../../select/select.component';
import toast from 'react-hot-toast';
import Autocomplete, { AutocompleteItem } from '../../../components/autocomplete/autocomplete';
import { useHashTag } from '../../../hooks/hashtag/useHashtag';
import ButtonPrimary from '../../../components/button-refactor/button-primary';
import Avatar from '../../../components/avatar/avatar.component';
import _get from 'lodash/get';
import { RootState } from '../../../configuration';
import { useSelector } from 'react-redux';
import { convertPostCategory } from '../../../utils/convertData.util';

interface PostCategory {
  id: string;
  slug: string;
  name: string;
  description: string;
  thumbnail: string;
  status: number;
  isSoftDeleted: boolean;
  createdAt: Date;
  updatedAt: Date;
}

const ModalSharePost = (props: ModalProps) => {
  const { isOpen, reset, isEdit, onClose, onClearIdDetail, onOpen, onFetch, isGroup } = props;
  const [open, setOpen] = useState(isOpen);
  const [textAreaContent, setTextAreaContent] = useState<string>('');
  const [isChangeCategory, setIsChangeCategory] = useState(false);
  const [postCategory, setPostCategories] = useState<PostCategory[]>([]);
  const [categoryId, setCategoryId] = useState<string>('');
  const [shareId, setShareId] = useState<number>(0);
  const [detailPostData, setDetailPostData] = useState<any>({});
  const [idPostDetail, setIdPostDetail] = useState(isEdit);
  const { profile } = useUserProfile();
  const profileUser = useSelector((state: RootState) => state.features.auth.profile);

  const handleClose = () => {
    reset?.(false);

    if (onOpen) {
      onOpen();
    }

    if (onClose) {
      onClose(true);
    }
    if (onClearIdDetail) {
      onClearIdDetail();
    }
    setIdPostDetail('');
  };

  const handleChangTextArea = (e) => {
    setTextAreaContent(e.target.value);
  };

  const [hashTags, setHashTags] = useState<string>('');

  useEffect(() => {
    handleGetPostCategories();
  }, []);

  const handleGetPostCategories = async () => {
    const res = await getCategory();
    setPostCategories(res as unknown as PostCategory[]);
  };

  const getCategory = async () => {
    const res = await fetchPostCategoriesApi();
    return res;
  };

  const validateForm = () => {
    if (!textAreaContent) {
      toast.error('Nhập nội dung', {
        position: 'top-right',
      });
      return false;
    }

    if (!categoryId && !isGroup) {
      toast.error('Chọn danh mục', {
        position: 'top-right',
      });
      return false;
    }
    if (!hashTags) {
      toast.error('Vui lòng nhập hashtag', {
        position: 'top-right',
      });
      return false;
    }
    return true;
  };

  const handleCreateNewPost = async () => {
    reset?.(false);
    // if (!validateForm()) {
    //   return;
    // }

    // if (!isGroup) {
    //   const data = {
    //     userId,
    //     categoryId,
    //     hashtags: hashTags,
    //     content: textAreaContent,
    //     title: textAreaContent,
    //   };

    //   const newPost = await createNewPost(data);
    //   setOpen(false);
    //   onCreatePost?.(newPost);
    // } else if (isGroup) {
    //   const data = {
    //     hashtags: hashTags,
    //     content: textAreaContent,
    //     title: textAreaContent,
    //     groupId,
    //   };

    //   const newPost = await createNewPostInGroup(data);
    //   setOpen(false);
    //   onCreatePost?.(newPost);
    // }
  };

  useEffect(() => {
    if (idPostDetail) {
      handleGetDetailPost(idPostDetail);
    }
  }, [idPostDetail]);

  const handleGetDetailPost = async (postId) => {
    const res = await getDetailPost(postId);
    setDetailPostData(res);
  };

  useEffect(() => {
    setTextAreaContent(detailPostData.content);
    setCategoryId(detailPostData.categoryId);
    if (typeof detailPostData?.hashtags === 'string') {
      setHashTags(detailPostData?.hashtags);
    } else if (detailPostData?.hashtags?.isArray()) {
      const listHashtagArray = detailPostData?.hashtags;
      const hashtags = listHashtagArray?.join(', ');
      setHashTags(hashtags);
    }
  }, [detailPostData]);

  const shareOptions = [
    {
      label: 'Public',
      value: 0,
    },
    {
      label: 'Bạn bè',
      value: 1,
    },
    {
      label: 'Private',
      value: 2,
    },
  ];

  const categoryOptions = useMemo(() => {
    const options = postCategory.map((category) => {
      const res: IOption = {
        label: category.name,
        value: category.id,
      };
      return res;
    });

    return options;
  }, [postCategory]);

  const { hashtags: hashtagsResponse, searchHashTags } = useHashTag();

  const hashTagOptions = useMemo(() => {
    if (!hashtagsResponse) {
      return [];
    }

    const res: AutocompleteItem[] = hashtagsResponse.map((hashtag) => {
      const option: AutocompleteItem = {
        label: hashtag.name,
        value: hashtag.name,
      };

      return option;
    });

    return res;
  }, [hashtagsResponse]);

  return (
    <DialogComponent onClose={() => handleClose()} visible={open} placement="CENTER" title="Chia sẽ bài viết" size="xl">
      <div className="w-full pb-5">
        <div className="">
          <div className="flex py-2">
            <Avatar avatar={_get(profile, 'avatar', '')} label={_get(profile, 'fullname', '')} size="sm" />
            <div className="ml-2">
              <h3 className="text-slate-800 dark:text-slate-50 font-semibold text-sm">
                {_get(profileUser, 'fullname', '')}
              </h3>
              <div className="flex items-center gap-2">
                {!isChangeCategory && !isGroup && (
                  <p
                    onClick={() => setIsChangeCategory(true)}
                    className="h-max text-slate-600 dark:text-slate-50 text-xs cursor-pointer border border-solid rounded-[4px] px-2 py-1 "
                  >
                    {categoryOptions.find((item: any) => item.value === categoryId)?.label
                      ? categoryOptions.find((item: any) => item.value === categoryId)?.label
                      : 'Chọn danh mục'}
                  </p>
                )}
                {isChangeCategory &&
                  (!idPostDetail ? (
                    <Select
                      options={categoryOptions}
                      onChange={(data: any) => {
                        setCategoryId(data.value);
                        setIsChangeCategory(false);
                      }}
                      placeholder="Chọn danh mục"
                    />
                  ) : (
                    <span className="outline-none border border-[#1D8DE3] text-[#1D8DE3] rounded-[4px] float-right min-w-[100px] text-center">
                      {convertPostCategory(categoryId)}
                    </span>
                  ))}

                <Select
                  options={shareOptions}
                  onChange={(data: any) => {
                    setShareId(data.value);
                  }}
                  placeholder="Chọn danh mục"
                  value={shareId}
                />
              </div>
            </div>
          </div>

          <div className="w-full border border-solid rounded-lg p-4 pb-12">
            <div className="flex gap-2 pb-5">
              <TextareaAutosize
                className="outline-none  text-slate-800 dark:text-slate-50 bg-zinc-50 dark:bg-transparent
              rounded-md w-full resize-none text-sm"
                onChange={(e) => handleChangTextArea(e)}
                value={textAreaContent}
                placeholder="Bạn đang nghĩ gì?"
                autoFocus
              >
                {textAreaContent}
              </TextareaAutosize>
            </div>
          </div>
          <div className="py-2">
            {!idPostDetail && hashtagsResponse ? (
              <Autocomplete
                items={hashTagOptions}
                onChange={(value) => {
                  setHashTags(value as string);
                }}
                onNoneItem={(inputValue) => {
                  searchHashTags(inputValue);
                }}
                placeholder="Hashtag"
              />
            ) : (
              <span className="inline-block flex-1 text-xs">{hashTags}</span>
            )}
          </div>

          <ButtonPrimary onClick={handleCreateNewPost} className="w-full mt-4 flex justify-center">
            Chia sẽ
          </ButtonPrimary>
        </div>
      </div>
    </DialogComponent>
  );
};

export default ModalSharePost;
