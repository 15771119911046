import React from 'react';
import { Card, Skeleton } from 'antd';

const CreatorSkeleton: React.FC = () => {
  return (
    <Card
      hoverable
      className="min-w-36 relative bg-white shadow-md rounded-lg overflow-hidden"
      bodyStyle={{ padding: 0 }}
    >
      <div className="absolute top-4 right-4 text-white cursor-pointer hover:text-gray-700 p-4 z-10 rounded-full bg-gray-300">
        {' '}
      </div>
      <div className="w-full h-20 bg-gray-200"> </div>
      <div className="h-full flex flex-col relative p-4">
        <Skeleton.Avatar active size={80} shape="circle" className="absolute left-2 -top-[50px]" />
        <div className="mt-4 flex gap-1 items-center">
          <Skeleton.Input active size="small" className="w-1/2" />
          <Skeleton.Input active size="small" className="w-1/4" />
        </div>
        <div className="flex-grow mt-2">
          <div className="flex justify-between items-center mb-2">
            <Skeleton.Input active size="small" className="w-1/2" />
          </div>
        </div>
        <Skeleton.Button active size="large" block />
      </div>
    </Card>
  );
};

export default CreatorSkeleton;
