import { useCallback, useEffect, useState } from 'react';

import creatorService from '../../services/creator.service';
import { ICreator } from '../../types/creator';

const useCreatorSuggestion = () => {
  const [creators, setCreators] = useState<ICreator[] | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [matchFavCreators, setMatchFavCreators] = useState<ICreator[] | undefined>(undefined);

  const getMatchFavCreators = useCallback(async () => {
    try {
      const data = await creatorService.getCreatorMatchInterestUser();
      setMatchFavCreators(data);
    } catch (error) {
      console.log('error', error);
    }
  }, []);

  const getCreatorsSuggestion = useCallback(async () => {
    setLoading(true);
    try {
      const data = await creatorService.getCreatorSuggestion();
      setCreators(data);
    } catch (error) {
      console.log('error', error);
      setCreators([]);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }, []);

  const removeCreatorSuggestion = useCallback(
    async (userId: string) => {
      try {
        await creatorService.removeCreatorSuggestion({ userId }).then(() => {
          getCreatorsSuggestion();
          getMatchFavCreators();
        });
      } catch (error) {
        console.log('error', error);
      }
    },
    [getCreatorsSuggestion, getMatchFavCreators],
  );

  useEffect(() => {
    getCreatorsSuggestion();
    getMatchFavCreators();
  }, [getCreatorsSuggestion, getMatchFavCreators]);

  return { creators, loading, getCreatorsSuggestion, removeCreatorSuggestion, getMatchFavCreators, matchFavCreators };
};

export default useCreatorSuggestion;
