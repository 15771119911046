import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { CreateGroupRequest } from '../../../../models';
import { groupService } from '../../../../services/group.service';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import { useGroupHook } from '../../../../hooks/group/useGroup';

const SettingTab = ({ data, refetchData }: { data: any; refetchData: any }) => {
  const [formData, setFormData] = useState(data?.group);
  const { groupId } = useParams<{ groupId: string }>();
  const [isRefetch, setIsRefetch] = useState<boolean>(false);
  const { updateGroup } = useGroupHook({ groupId, isRefetch });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<CreateGroupRequest>({ defaultValues: formData });
  const onSubmit = async (data: CreateGroupRequest) => {
    setIsRefetch(!isRefetch);
    await updateGroup(data, groupId);
    refetchData && refetchData();
  };

  return (
    <div
      className="bg-white rounded-xl shadow-sm md:p-4 p-2 space-y-4 text-sm font-medium
    border-1 dark:bg-dark2 mt-4"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-6">
          <h3 className="text-lg font-semibold mb-3">Thông tin cơ bản</h3>
          <div>
            <label htmlFor="groupName" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
              Tên nhóm
            </label>
            <input
              {...register('groupName', { required: 'Tên nhóm là bắt buộc' })}
              type="text"
              id="groupName"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            />
            {errors.groupName && <p className="mt-1 text-sm text-red-600">{errors.groupName.message}</p>}
          </div>
          <div className="mt-4">
            <label htmlFor="groupType" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
              Loại nhóm
            </label>
            <select
              {...register('groupType', { required: 'Loại nhóm là bắt buộc' })}
              id="groupType"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            >
              <option value={0}>Công khai</option>
              <option value={1}>Riêng tư</option>
              <option value={2}>Bí mật</option>
            </select>
            {errors.groupType && <p className="mt-1 text-sm text-red-600">{errors.groupType.message}</p>}
          </div>

          <div className="mt-4">
            <label htmlFor="groupDescription" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
              Mô tả nhóm
            </label>
            <textarea
              {...register('groupDescription', { required: 'Mô tả nhóm là bắt buộc' })}
              id="groupDescription"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            />
            {errors.groupDescription && <p className="mt-1 text-sm text-red-600">{errors.groupDescription.message}</p>}
          </div>
        </div>
        <div className="mb-6">
          <h3 className="text-lg font-semibold mb-3">Thông tin bổ sung</h3>
          <div>
            <label htmlFor="groupTagline" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
              Khẩu hiệu nhóm
            </label>
            <input
              {...register('groupTagline')}
              type="text"
              id="groupTagline"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            />
          </div>

          <div className="mt-4">
            <label htmlFor="rules" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
              Quy tắc nhóm
            </label>
            <textarea
              {...register('rules')}
              id="rules"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            />
          </div>

          <div className="mt-4">
            <label htmlFor="location" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
              Vị trí
            </label>
            <input
              {...register('location')}
              type="text"
              id="location"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            />
          </div>
          <div className="mt-4">
            <label htmlFor="tagList" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
              Danh sách thẻ
            </label>
            <div className="flex flex-wrap gap-2">
              <input
                {...register('tagList')}
                type="text"
                id="tagList"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && e.currentTarget.value.trim() !== '') {
                    e.preventDefault();
                    const newTag = e.currentTarget.value.trim();
                    const currentTags = e.currentTarget.value
                      .split(',')
                      .map((tag) => tag.trim())
                      .filter((tag) => tag);
                    currentTags.push(newTag);
                    e.currentTarget.value = currentTags.join(', ');
                  }
                }}
              />
            </div>
          </div>
        </div>
        <div className="mb-6">
          <h3 className="text-lg font-semibold mb-3">Cài đặt</h3>
          <div>
            <label htmlFor="privacySettings" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
              Cài đặt quyền riêng tư
            </label>
            <select
              {...register('privacySettings')}
              id="privacySettings"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            >
              <option value="public">Công khai</option>
              <option value="private">Riêng tư</option>
            </select>
          </div>

          <div className="mt-4">
            <input
              {...register('approvalRequired')}
              type="checkbox"
              id="approvalRequired"
              className="rounded border-gray-300 text-indigo-600 shadow-sm focus:border-indigo-300 focus:ring focus:ring-offset-0 focus:ring-indigo-200 focus:ring-opacity-50"
            />
            <label htmlFor="approvalRequired" className="ml-2 text-sm text-gray-700 dark:text-gray-300">
              Yêu cầu phê duyệt khi tham gia nhóm
            </label>
          </div>
        </div>
        <div className="flex justify-end">
          <button
            type="submit"
            className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Cập nhật thông tin
          </button>
        </div>
      </form>
    </div>
  );
};

export default SettingTab;
