import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useHashTag } from '../../hooks/hashtag/useHashtag';
import Loading from '../../components/loading/loading.component';
import { Post } from '../../components/post/post.component';
import { RootState } from '../../configuration';
import { useSelector } from 'react-redux';
import _get from 'lodash/get';
import _debounce from 'lodash/debounce';
import InfiniteScroll from 'react-infinite-scroll-component';
import InputComponent from '../../components/input/input.component';
import Select from '../../select/select.component';

const HashtagPage: React.FC = () => {
  const { hashtagId } = useParams<{ hashtagId: string }>();
  const { loading, getPostByHashtag, postsByHashTag, hasMore: hasMorePosts } = useHashTag();
  const userProfile = useSelector((state: RootState) => state.features.auth.profile);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [orderType, setOrderType] = useState(null);

  const orderTypeOption = [
    { label: 'Mới nhất', value: 1 },
    { label: 'Cũ nhất', value: 0 },
  ];

  useEffect(() => {
    if (hashtagId) {
      getPostByHashtag({
        userId: _get(userProfile, 'userId', ''),
        hashTag: hashtagId,
        pageNumber: page,
        pageSize: 10,
        search,
        orderType,
      });
    }
  }, [hashtagId, page, userProfile, search, orderType]);

  const fetchMoreData = () => {
    if (!hasMorePosts) return;
    setPage(page + 1);
  };

  const handleChangeSearch = useCallback(
    _debounce((event) => {
      setSearch(event.target.value);
      setPage(1);
    }, 400),
    [],
  );

  return (
    <div className="max-w-7xl mx-auto">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Posts for #{hashtagId}</h1>

        <div className="grid grid-cols-2 gap-2 w-1/2">
          <InputComponent
            onChange={handleChangeSearch}
            placeholder="Tìm kiếm"
            className="border-[1px] !border-black border-solid"
          />
          <Select
            className="border-[1px] border-black border-solid"
            options={orderTypeOption}
            onChange={(data: any) => {
              setOrderType(data.value);
              setPage(1);
            }}
            placeholder="Chọn thời gian"
            value={orderType}
          />
        </div>
      </div>

      {postsByHashTag && postsByHashTag.data.length === 0 && !loading ? (
        <p className="text-center">Không tìm thấy bài viết nào cho hashtag này.</p>
      ) : (
        <InfiniteScroll
          dataLength={postsByHashTag?.data.length || 0}
          next={fetchMoreData}
          hasMore={hasMorePosts}
          loader={<Loading classname="mx-auto" />}
        >
          <div className="space-y-6">
            {postsByHashTag?.data.map((post) => (
              <Post key={post.id} post={post} />
            ))}
          </div>
        </InfiniteScroll>
      )}
    </div>
  );
};

export default HashtagPage;
