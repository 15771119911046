import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ClickOutside from '../ClickOutside';
import UserOne from '../../../../images/user/user-01.png';
import { LocalStorageService } from '../../../../configuration';
import { googleLogout } from '@react-oauth/google';

const DropdownUser = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const navigate = useNavigate();
  const handleLogout = () => {
    LocalStorageService.removeItem('accessToken');
    LocalStorageService.removeItem('fingerprintHash');
    LocalStorageService.removeItem('refreshToken');
    googleLogout();
    navigate('admin/login');
  };
  return (
    <ClickOutside onClick={() => setDropdownOpen(false)} className="relative">
      <Link onClick={() => setDropdownOpen(!dropdownOpen)} className="flex items-center gap-4" to="#">
        <span className="hidden text-right lg:block">
          <span className="block text-sm font-medium text-black dark:text-white">Admin</span>
        </span>

        <span className="h-12 w-12 rounded-full">
          <img src={UserOne} alt="User" />
        </span>
      </Link>

      {/* <!-- Dropdown Start --> */}
      {dropdownOpen && (
        <div className="absolute top-14 right-0 rounded-md max-w-[156px] w-[500px] bg-white dark:bg-gray-900 space-y-3 p-4 drop-shadow-xl">
          <button
            className="rounded-md flex justify-center items-center gap-2 h-10 text-sm px-4 py-2 bg-red-500 hover:bg-red-600 text-white dark:text-gray-100 transition duration-200 w-full"
            onClick={handleLogout}
          >
            Đăng xuất
          </button>
        </div>
      )}
      {/* <!-- Dropdown End --> */}
    </ClickOutside>
  );
};

export default DropdownUser;
