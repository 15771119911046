import _ from 'lodash';
import React from 'react';
import { RootState } from '../../../configuration';
import { useSelector } from 'react-redux';
import { CircleCheck } from 'lucide-react';
import Avatar from '../../../components/avatar/avatar.component';

const AsideRight = () => {
  const profileUser = useSelector((state: RootState) => state.features.auth.profile);

  return (
    <aside
      className="hidden lg:block flex-1 sticky top-20 overflow-auto no-scrollbar"
      style={{
        height: 'calc(100vh - 84px)',
      }}
    >
      <div className="space-y-4">
        <section>
          <div className="box border1 dark:bg-dark2">
            <div className="rounded-lg h-32 overflow-hidden">
              <img
                className="object-cover object-top w-full h-32"
                src={_.get(profileUser, 'coverImage', '') || 'https://www.w3schools.com/howto/img_avatar.png'}
                alt="Mountain"
              />
            </div>
            <div className="flex flex-col justify-center  md:items-center -mt-16">
              <div className=" relative  border-4 border-white rounded-full">
                <Avatar
                  avatar={_.get(profileUser, 'avatar', '')}
                  label={_.get(profileUser, 'requestFullName', '')}
                  size="xl"
                />
              </div>
            </div>
            <div className="text-center mt-2">
              <h2 className="font-semibold flex justify-center items-center gap-2">
                {profileUser?.fullname}

                {_.get(profileUser, 'userType') === 'CONTENT_CREATOR' ? (
                  <CircleCheck color="green" size={16} />
                ) : (
                  profileUser?.isVerifyEmail && <CircleCheck color="black" size={16} />
                )}
              </h2>
              <p className="text-blue-500">@{profileUser?.username}</p>
            </div>
            <ul className="flex items-center justify-center mt-1">
              <li className="m-2">
                <h4 className="font-bold text-sm text-center">
                  {0}
                  <span className="text-[10px] font-medium mt-1 text-gray-500 block">Lượt quẹt</span>
                </h4>
              </li>
              <li className="m-2">
                <h4 className="font-bold text-sm text-center">
                  {0}
                  <span className="text-[10px] font-medium mt-1 text-gray-500 block">Đã kết nối</span>
                </h4>
              </li>
              <li className="m-2">
                <h4 className="font-bold text-sm text-center">
                  {0}
                  <span className="text-[10px] font-medium mt-1 text-gray-500 block">Đã gửi kết nối</span>
                </h4>
              </li>
              <li className="m-2">
                <h4 className="font-bold text-sm text-center">
                  {0}
                  <span className="text-[10px] font-medium mt-1 text-gray-500 block">Số tiền</span>
                </h4>
              </li>
            </ul>
          </div>
        </section>
        {/* <section>
          <div className="box border1 dark:bg-dark2">
            <h3>Chat</h3>
          </div>
        </section> */}
      </div>
    </aside>
  );
};

export default AsideRight;
