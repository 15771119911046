import React, { useState } from 'react';
import { Layout, Typography, Row, Col, Card } from 'antd';

import useCreatorSuggestion from '../../hooks/use-creator-suggestion/useCreatorSuggestion';
import Creator from '../../components/creator/creator.component';
import { CreatorSkeleton } from '../../components/creator';
import { useNavigate } from 'react-router-dom';

const { Content } = Layout;
const { Title } = Typography;

const CreatorSuggestionPage: React.FC = () => {
  const { creators, getCreatorsSuggestion, removeCreatorSuggestion, getMatchFavCreators, matchFavCreators } =
    useCreatorSuggestion();
  const navigate = useNavigate();
  const [visibleCount, setVisibleCount] = useState(8);
  const [visibleMatchFavCount, setVisibleMatchFavCount] = useState(8);
  const renderSkeletons = () => {
    return Array(8)
      .fill(null)
      .map((_, index) => (
        <Col key={index} span={6}>
          <CreatorSkeleton />
        </Col>
      ));
  };

  const renderNotFound = () => {
    return (
      <Col span={24}>
        <div className="text-center py-8">
          <p className="text-gray-500 text-lg">Không tìm thấy nhà sáng tạo nào</p>
        </div>
      </Col>
    );
  };
  return (
    <Layout className="min-h-screen bg-gray-100">
      <Content className="max-w-7xl mx-auto w-full py-4 sm:py-6 lg:py-8 px-4 sm:px-6 lg:px-8">
        <Card className="mb-4 sm:mb-6">
          <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4">
            <Title level={4} className="text-gray-900 m-0 mb-2 sm:mb-0">
              Creator nổi bật
            </Title>
          </div>
          <Row gutter={[16, 16]}>
            {creators === undefined ? (
              renderSkeletons()
            ) : creators.length === 0 ? (
              renderNotFound()
            ) : (
              <>
                {creators.slice(0, visibleCount).map((creator) => (
                  <Col key={creator.userId} xs={24} sm={12} md={12} lg={6} className="relative">
                    <Creator
                      creator={creator}
                      onClick={() => navigate(`/user/${creator.userId}`)}
                      onSuccess={getCreatorsSuggestion}
                      removeSuggestion={removeCreatorSuggestion}
                    />
                  </Col>
                ))}
                {visibleCount < creators.length && (
                  <Col span={24} className="text-center mt-4">
                    <button
                      onClick={() => setVisibleCount((prev) => prev + 8)}
                      className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
                    >
                      Xem thêm
                    </button>
                  </Col>
                )}
              </>
            )}
          </Row>
        </Card>

        <Card className="mb-4 sm:mb-6">
          <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4">
            <Title level={4} className="text-gray-900 m-0 mb-2 sm:mb-0">
              Creator bạn có thể thích
            </Title>
          </div>
          <Row gutter={[16, 16]}>
            {matchFavCreators === undefined
              ? renderSkeletons()
              : matchFavCreators.length === 0
              ? renderNotFound()
              : matchFavCreators.slice(0, visibleMatchFavCount).map((creator) => (
                  <Col key={creator.userId} xs={24} sm={12} md={12} lg={6} className="relative">
                    <Creator
                      creator={creator}
                      onSuccess={getMatchFavCreators}
                      removeSuggestion={removeCreatorSuggestion}
                    />
                  </Col>
                ))}
            {visibleMatchFavCount < (matchFavCreators?.length ?? 0) && (
              <Col span={24} className="text-center mt-4">
                <button
                  onClick={() => setVisibleMatchFavCount((prev) => prev + 8)}
                  className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
                >
                  Xem thêm
                </button>
              </Col>
            )}
          </Row>
        </Card>
      </Content>
    </Layout>
  );
};

export default CreatorSuggestionPage;
