import React, { FC, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Save } from 'lucide-react';
import { IPostModel } from '../../models';
import Avatar from '../avatar/avatar.component';
import dayjs from 'dayjs';
import PreviewPostDialog from '../preview-post-dialog/preview-post-dialog.componet';

interface BookmarkProps {
  post: IPostModel;
  onToggleBookmark: (postId: string) => void;
}

const BookmarkCard: FC<BookmarkProps> = ({ post, onToggleBookmark }) => {
  const [visiblePreview, setVisiblePreview] = useState(false);
  const [indexViewImage, setIndexViewImage] = useState(0);
  const navigate = useNavigate();
  return (
    <div className="bg-white dark:bg-gray-900 rounded-lg shadow p-4 flex relative hover:shadow-md ">
      {post?.postImageResponses && post.postImageResponses.length > 0 && post.postImageResponses[0].url && (
        <div
          className="relative w-[100px] h-[100px] mr-3 cursor-pointer"
          onClick={() => {
            setIndexViewImage(0);
            setVisiblePreview(true);
          }}
        >
          <img src={post.postImageResponses[0].url} alt="" className="w-full h-full object-cover rounded-lg" />
        </div>
      )}
      {post?.postVideoResponses && post.postVideoResponses.length > 0 && post.postVideoResponses[0].url && (
        <div
          className="relative w-[100px] h-[100px] mr-3 cursor-pointer"
          onClick={() => {
            navigate(`/videos/${post.id}`);
          }}
        >
          <video className="w-full h-full object-cover rounded-lg">
            <source src={post.postVideoResponses[0].url} type="video/mp4" />
            <track kind="captions" src="" label="English captions" />
            Your browser does not support the video tag.
          </video>
          <div className="absolute inset-0 flex items-center justify-center">
            <div className="w-10 h-10 bg-black bg-opacity-50 rounded-full flex items-center justify-center">
              <div className="w-0 h-0 border-t-[8px] border-t-transparent border-l-[12px] border-l-white border-b-[8px] border-b-transparent ml-1">
                {' '}
              </div>
            </div>
          </div>
        </div>
      )}
      {!post.postImageResponses?.length && !post.postVideoResponses?.length && (
        <div className="relative w-[100px] h-[100px] mr-3 bg-gray-100 dark:bg-gray-800 rounded-lg flex items-center justify-center">
          {' '}
        </div>
      )}
      <div className="flex items-start space-x-3">
        <div className="flex-1">
          <div className="flex flex-col">
            <Link to={`/user/${post?.userId}`} className="text-slate-800 dark:text-slate-50 text-sm font-bold">
              {post?.userFullName || 'Người dùng không xác định'}
            </Link>

            <div className="flex items-center space-x-2 text-xs text-gray-500">
              <span className="text-branding font-semibold">Công nghệ</span>
              <span>•</span>
              <span>{dayjs.utc(post?.createdAt).fromNow()} trước</span>
            </div>
          </div>

          <div className="mt-2">
            <p className="text-sm text-zinc-800 dark:text-zinc-50 line-clamp-2">{post?.content}</p>

            {post?.hashtags && (
              <div className="flex flex-wrap gap-1 mt-2">
                {post.hashtags.split(' ').map((tag, index) => (
                  <span
                    key={index}
                    className="text-violet-800 dark:text-violet-500 text-sm hover:underline cursor-pointer"
                  >
                    #{tag}
                  </span>
                ))}
              </div>
            )}
          </div>
        </div>

        <button
          className="flex items-center space-x-2 absolute top-4 right-4"
          onClick={() => post?.id && onToggleBookmark(post.id)}
        >
          <Save size={16} className="text-gray-600 dark:text-gray-400" />
          <span className="text-xs whitespace-nowrap font-semibold">Bỏ lưu</span>
        </button>
      </div>

      {visiblePreview && (
        <PreviewPostDialog
          visible={visiblePreview}
          onclose={() => setVisiblePreview(false)}
          post={post}
          initialSlide={indexViewImage}
        />
      )}
    </div>
  );
};

export default BookmarkCard;
