import React, { useEffect, useState } from 'react';
import { IUserProfile } from '../../models';
import InputComponent from '../input/input.component';
import * as yup from 'yup';
import ButtonSecondary from '../button-refactor/button-secondary';
import ButtonPrimary from '../button-refactor/button-primary';
import { SubmitHandler, useForm, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { userService } from '../../services/user.service';
import toast from 'react-hot-toast';
import ErrorValidateComponent from '../error-validate/error-validate.component';
import Select, { IOption } from '../../select/select.component';
import { fetchAllCategory } from '../../api';

const options: IOption[] = [
  {
    label: 'Nam',
    value: 1,
  },
  {
    label: 'Nữ',
    value: 2,
  },
  {
    label: 'LGBT',
    value: 3,
  },
  {
    label: 'LGBTQ+',
    value: 4,
  },
];

interface EditProfiledDialogProps {
  profile: IUserProfile;
  onUpdateSuccess: () => void;
}

const schema = yup.object().shape({});

interface SchoolCompany {
  name: string;
  from: string;
  to: string;
}

interface IFormValues {
  school?: string;
  company?: string;
  marry?: number;
  categoryFavorites?: any;
  sexualOrientation?: number;
  schools?: SchoolCompany[];
  companys?: SchoolCompany[];
}

const EditProfileOrther = ({ profile, onUpdateSuccess }: EditProfiledDialogProps) => {
  const { school, company, categoryFavorites, marry, sexualOrientation } = profile;
  const [visible, setVisible] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [categoryOptions, setCategoryOptions] = useState<IOption[]>([]);
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    control,
    formState: { errors },
  } = useForm<IFormValues>({
    resolver: yupResolver(schema),
    defaultValues: {
      school,
      company,
      categoryFavorites,
      marry,
      sexualOrientation,
    },
  });

  const {
    fields: fieldSchool,
    append: appendSchool,
    remove: removeSchool,
  } = useFieldArray({
    control,
    name: 'schools',
  });

  const {
    fields: fieldCompany,
    append: appendCompany,
    remove: removeCompany,
  } = useFieldArray({
    control,
    name: 'companys',
  });

  const onSubmit: SubmitHandler<IFormValues> = async (formValues) => {
    try {
      setLoading(true);

      const payload = {
        ...profile,
        ...formValues,
        categoryFavorites: formValues.categoryFavorites
          ? formValues.categoryFavorites
          : profile.categoryFavorites.map((item) => item.categoryId),
        email: profile.email,
      };
      await userService.updateProfile(payload as IUserProfile);

      onUpdateSuccess();
      setVisible(false);
    } catch (error) {
      toast.error('Không thể cập nhật tài khoản của bạn');
      setVisible(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form className="space-y-4 py-5 font-bold" onSubmit={handleSubmit(onSubmit)}>
      <div className="form-group ">
        <div className="flex">
          <label htmlFor="fullname" className="flex items-center w-[20%] text-sm text-slate-800 dark:text-slate-50 ">
            Trường học
          </label>
          <div className="w-full">
            {fieldSchool?.length === 0 ? (
              <ButtonPrimary onClick={() => appendSchool({ name: '', from: '', to: '' })}>
                Thêm trường học
              </ButtonPrimary>
            ) : (
              fieldSchool?.map((x, index) => (
                <div className="flex flex-col gap-2" key={index}>
                  <div className="flex flex-col gap-1">
                    <InputComponent placeholder="Tên trường" id="name" register={register(`schools.${index}.name`)} />

                    <ErrorValidateComponent visible={!!errors.school}>
                      {errors.schools?.[index]?.name?.message}
                    </ErrorValidateComponent>
                  </div>

                  <div className="grid grid-cols-2 gap-2">
                    <div className="flex flex-col gap-1">
                      <label
                        htmlFor="fullname"
                        className="flex items-center text-xs font-medium text-slate-800 dark:text-slate-50 "
                      >
                        Thời gian bắt đầu
                      </label>
                      <InputComponent
                        placeholder="Thời gian bắt đầu"
                        id="from"
                        register={register(`schools.${index}.from`)}
                        type="date"
                      />
                      <ErrorValidateComponent visible={!!errors.schools?.[index]?.from}>
                        {errors.schools?.[index]?.from?.message}
                      </ErrorValidateComponent>
                    </div>
                    <div className="flex flex-col gap-1">
                      {' '}
                      <label
                        htmlFor="fullname"
                        className="flex items-center text-xs font-medium text-slate-800 dark:text-slate-50 "
                      >
                        Thời gian kết thúc
                      </label>
                      <InputComponent
                        placeholder="Thời gian kết thúc"
                        id="to"
                        register={register(`schools.${index}.to`)}
                        type="date"
                      />
                      <ErrorValidateComponent visible={!!errors.schools?.[index]?.to}>
                        {errors.schools?.[index]?.to?.message}
                      </ErrorValidateComponent>
                    </div>
                  </div>
                </div>
              ))
            )}
            {fieldSchool?.length === 1 && (
              <ButtonSecondary className="mt-2" onClick={() => removeSchool(0)}>
                Hủy
              </ButtonSecondary>
            )}
          </div>
        </div>
      </div>
      <div className="form-group ">
        <div className="flex">
          <label htmlFor="fullname" className="flex items-center w-[20%] text-sm text-slate-800 dark:text-slate-50 ">
            Nơi làm việc
          </label>
          <div className="w-full">
            {fieldCompany?.length === 0 ? (
              <ButtonPrimary onClick={() => appendCompany({ name: '', from: '', to: '' })}>
                Thêm nơi làm việc
              </ButtonPrimary>
            ) : (
              fieldCompany?.map((x, index) => (
                <div className="flex flex-col gap-2" key={index}>
                  <div className="flex flex-col gap-1">
                    <InputComponent placeholder="Tên công ty" id="name" register={register(`companys.${index}.name`)} />

                    <ErrorValidateComponent visible={!!errors.school}>
                      {errors.companys?.[index]?.name?.message}
                    </ErrorValidateComponent>
                  </div>

                  <div className="grid grid-cols-2 gap-2">
                    <div className="flex flex-col gap-1">
                      <label
                        htmlFor="fullname"
                        className="flex items-center text-xs font-medium text-slate-800 dark:text-slate-50 "
                      >
                        Thời gian bắt đầu
                      </label>
                      <InputComponent
                        placeholder="Thời gian bắt đầu"
                        id="from"
                        register={register(`companys.${index}.from`)}
                        type="date"
                      />
                      <ErrorValidateComponent visible={!!errors.companys?.[index]?.from}>
                        {errors.companys?.[index]?.from?.message}
                      </ErrorValidateComponent>
                    </div>
                    <div className="flex flex-col gap-1">
                      <label
                        htmlFor="fullname"
                        className="flex items-center text-xs font-medium text-slate-800 dark:text-slate-50 "
                      >
                        Thời gian kết thúc
                      </label>
                      <InputComponent
                        placeholder="Thời gian kết thúc"
                        id="to"
                        register={register(`companys.${index}.to`)}
                        type="date"
                      />
                      <ErrorValidateComponent visible={!!errors.companys?.[index]?.to}>
                        {errors.companys?.[index]?.to?.message}
                      </ErrorValidateComponent>
                    </div>
                  </div>
                </div>
              ))
            )}
            {fieldCompany?.length === 1 && (
              <ButtonSecondary className="mt-2" onClick={() => removeCompany(0)}>
                Hủy
              </ButtonSecondary>
            )}
          </div>
        </div>
      </div>

      <div className="form-group flex justify-end items-center">
        <ButtonSecondary className="mr-4" onClick={() => setVisible(false)}>
          Hủy bỏ
        </ButtonSecondary>
        <ButtonPrimary loading={loading}>Cập nhật</ButtonPrimary>
      </div>
    </form>
  );
};

export default EditProfileOrther;
