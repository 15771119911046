import { useState, useEffect } from 'react';
import { IDatingProfileModel } from '../../models/dating.model';
import { datingService } from '../../services/dating.service';
import _ from 'lodash';
// import { RootState } from '../../configuration';

export const useDating = () => {
  //   const profileUser = useSelector((state: RootState) => state.features.auth.profile);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [datingProfile, setDatingProfile] = useState<IDatingProfileModel>();
  const [userDatingProfiles, setUserDatingProfiles] = useState<IDatingProfileModel[]>([]);
  const [matchRequests, setMatchRequests] = useState<any[]>([]);
  const getDatingProfile = async () => {
    try {
      //
      const data = await datingService.getDatingProfile();
      setDatingProfile(_.get(data, 'data'));
    } catch {
      //
    }
  };

  const getUserDatingProfiles = async () => {
    try {
      setIsLoading(true);
      const data = await datingService.getUserDatingProfiles({});
      setUserDatingProfiles(_.get(data, 'data', []));
    } catch {
      //
    } finally {
      setIsLoading(false);
    }
  };

  const getMatchRequestsByProfileId = async (profileId: string) => {
    try {
      const data = await datingService.getMatchRequestsByProfileId(profileId);
      setMatchRequests(_.get(data, 'data', []));
    } catch {
      //
    }
  };

  useEffect(() => {
    getDatingProfile();
  }, []);

  return {
    isLoading,
    datingProfile,
    getDatingProfile,
    userDatingProfiles,
    getUserDatingProfiles,
    matchRequests,
    getMatchRequestsByProfileId,
  };
};
