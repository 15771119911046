import React from 'react';
import { useDating } from '../../../hooks/use-dating/useDating';
import _ from 'lodash';
import { IDatingProfileModel } from '../../../models/dating.model';
import { IOption } from '../../../select/select.component';

const gender: IOption[] = [
  {
    label: 'Nam',
    value: 'male',
  },
  {
    label: 'Nữ',
    value: 'female',
  },
  {
    label: 'Tùy chọn',
    value: 'other',
  },
];

const InfoField = ({ label, value }: { label: string; value: string | number }) => {
  return (
    <div className="flex gap-1">
      <span className="text-nowrap">{label}:</span>
      <span className="font-semibold text-black dark:text-white">{value || 'Chưa có'}</span>
    </div>
  );
};

const Profile = ({ profile }: { profile?: IDatingProfileModel }) => {
  return (
    <div className="flex flex-col gap-4 mt-8">
      <section className="bg-white rounded-xl shadow-sm text-sm font-medium border1 dark:bg-dark2 p-4">
        <div className="flex items-ce justify-between text-black dark:text-white">
          <h3 className="font-bold text-lg"> Thông tin cơ bản </h3>
        </div>
        <ul className="text-gray-700 space-y-4 mt-4 text-sm dark:text-white/80">
          <li className="">
            <InfoField label="Giới thiệu" value={_.get(profile, 'description') || 'Chưa có'} />
          </li>
          <li className="grid grid-cols-2">
            <InfoField label="Tên hiển thị ( Nickname )" value={_.get(profile, 'nickname') || 'Chưa có'} />
            <InfoField label="Tuổi" value={_.get(profile, 'age') || 'Chưa có'} />
          </li>
          <li className="grid grid-cols-2">
            <InfoField label="Quốc gia" value={_.get(profile, 'country') || 'Chưa có'} />
            <InfoField label="Tỉnh/Thành phố" value={_.get(profile, 'city') || 'Chưa có'} />
          </li>
          <li>
            <InfoField label="Địa chỉ" value={_.get(profile, 'additionLocation') || 'Chưa có'} />
          </li>
          <li className="grid grid-cols-2">
            <InfoField label="Chiều cao" value={_.get(profile, 'height') || 'Chưa có'} />
            <InfoField label="Cân nặng" value={_.get(profile, 'weight') || 'Chưa có'} />
          </li>
          <li>
            <InfoField
              label="Giới tính"
              value={
                _.find(gender, { value: _.get(profile, 'gender') })?.label || _.get(profile, 'gender') || 'Chưa có'
              }
            />
          </li>
        </ul>
      </section>
      <section className="bg-white rounded-xl shadow-sm text-sm font-medium border1 dark:bg-dark2 p-4">
        <div className="flex items-ce justify-between text-black dark:text-white">
          <h3 className="font-bold text-lg"> Nơi làm việc và học vấn </h3>
        </div>
        <ul className="text-gray-700 space-y-4 mt-4 text-sm dark:text-white/80">
          <li className="grid grid-cols-2">
            <InfoField label="Nghề nghiệp, công việc" value={_.get(profile, 'occupation') || 'Chưa có'} />
            <InfoField label="Học vấn" value={_.get(profile, 'education') || 'Chưa có'} />
          </li>
        </ul>
      </section>
      <section className="bg-white rounded-xl shadow-sm text-sm font-medium border1 dark:bg-dark2 p-4">
        <div className="flex items-ce justify-between text-black dark:text-white">
          <h3 className="font-bold text-lg"> Sở thích </h3>
        </div>
        <ul className="text-gray-700 space-y-4 mt-4 text-sm dark:text-white/80">
          <li className="grid grid-cols-2">
            <InfoField label="Sở thích" value={_.get(profile, 'interests', []).join(', ') || 'Chưa có'} />
          </li>
        </ul>
      </section>
      <section className="bg-white rounded-xl shadow-sm text-sm font-medium border1 dark:bg-dark2 p-4">
        <div className="flex items-ce justify-between text-black dark:text-white">
          <h3 className="font-bold text-lg"> Xu hướng </h3>
        </div>
        <ul className="text-gray-700 space-y-4 mt-4 text-sm dark:text-white/80">
          <li className="grid grid-cols-2">
            <InfoField label="Xu hướng giới tính" value={_.get(profile, 'genderPreference') || 'Chưa có'} />
            <InfoField label="Xu hướng tuổi" value={_.get(profile, 'agePreference') || 'Chưa có'} />
          </li>
        </ul>
      </section>
    </div>
  );
};

export default Profile;
