import { useEffect, useState } from 'react';
import { IPostModel, IPostResponse } from '../../models';
import { postService } from '../../services/post.service';
import { IHashTag, ISearchHashtagPayload } from '../../types/hashtag';

export const useHashTag = () => {
  const [hashtags, setHashTags] = useState<IHashTag[] | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [postsByHashTag, setPostsByHashTag] = useState<IPostResponse | null>(null);
  const [hasMore, setHasMore] = useState(true);

  const getHashTags = async () => {
    try {
      setLoading(true);
      const data = await postService.getHashTag(5);
      setHashTags(data);
    } catch (error) {
      console.error('Error fetching hashtags:', error);
    } finally {
      setLoading(false);
    }
  };

  const searchHashTags = async (keyword: string) => {
    try {
      setLoading(true);
      const payload: ISearchHashtagPayload = {
        keyword,
        totalRecord: 5,
      };
      const data = await postService.searchHashTag(payload);
      setHashTags(data);
    } catch (error) {
      console.error('Error searching hashtags:', error);
    } finally {
      setLoading(false);
    }
  };

  const getPostByHashtag = async (payload: {
    userId: string;
    hashTag: string;
    pageNumber: number;
    pageSize: number;
    search?: string;
    orderType?: number | null;
  }) => {
    try {
      setLoading(true);
      const data = await postService.getPostByHashtag(payload);
      setPostsByHashTag(data);
      setHasMore(data.hasMore);
    } catch (error) {
      console.error('Error fetching posts by hashtag:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getHashTags();
  }, []);

  return {
    hashtags,
    loading,
    getHashTags,
    searchHashTags,
    getPostByHashtag,
    postsByHashTag,
    hasMore,
  };
};
