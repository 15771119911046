import { useEffect, useState } from 'react';
import { fetchAllReqDonateAPI } from '../../../api/admin/req-donate.api';

export const useReqDonate = ({ isRefetch }: { isRefetch?: boolean }) => {
  const [dataList, setDataList] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  useEffect(() => {
    getAllReqDonate();
  }, [isRefetch]);
  const getAllReqDonate = async () => {
    try {
      setLoading(true);
      const { data } = await fetchAllReqDonateAPI();
      setDataList(data);
    } catch {
      //
    } finally {
      setLoading(false);
    }
  };
  return {
    data: dataList,
    loading,
  };
};
