import React, { useEffect, useState } from 'react';
import DialogComponent from '../../../components/dialog/dialog.component';
import { useForm, SubmitHandler } from 'react-hook-form';
import { IDatingProfileModel } from '../../../models/dating.model';
import InputComponent from '../../../components/input/input.component';
import Select, { IOption } from '../../../select/select.component';
import ReactSelect, { MultiValue } from 'react-select';
import { fetchAllCategory } from '../../../api';
import ButtonSecondary from '../../../components/button-refactor/button-secondary';
import ButtonPrimary from '../../../components/button-refactor/button-primary';
import toast from 'react-hot-toast';
import { datingService } from '../../../services/dating.service';

const options: IOption[] = [
  {
    label: 'Nam',
    value: 'male',
  },
  {
    label: 'Nữ',
    value: 'female',
  },
  {
    label: 'Tùy chọn',
    value: 'other',
  },
];

const optionsPreferredGender: IOption[] = [
  {
    label: 'Nam',
    value: 'MALE',
  },
  {
    label: 'Nữ',
    value: 'FEMALE',
  },
];

interface EditProfileGeneralProps {
  profile?: IDatingProfileModel;
  cities?: IOption[];
  getDatingProfile?: () => void;
}

interface IFormValues {
  nickname: string;
  age: number;
  gender: string;
  birthDay: string;
  description: string;
  additionLocation: string;
  cityId: string;
  albumUrls: string[];
  interestIds: string[];
  preferredGender: string;
  preferredAge: number;
  education: string;
  occupation: string;
  height: number;
  weight: number;
  customGender: string;
}

const EditProfile = ({ profile, cities, getDatingProfile }: EditProfileGeneralProps) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const openEditProfile = () => {
    setVisible(true);
  };

  const [categoryOptions, setCategoryOptions] = useState<IOption[]>([]);

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
    reset,
  } = useForm<IFormValues>({
    // resolver: yupResolver(schema),
    defaultValues: {
      nickname: 'testst',
      age: profile?.age,
      gender: profile?.gender,
      birthDay: profile?.birthDay,
      description: profile?.description,
      additionLocation: profile?.additionLocation,
      cityId: profile?.cityId,
      albumUrls: profile?.albumUrls || [],
      interestIds: profile?.interestIds || [],
      preferredGender: profile?.preferredGender,
      preferredAge: profile?.preferredAge,
      education: profile?.education,
      occupation: profile?.occupation,
      height: profile?.height,
      weight: profile?.weight,
    },
  });

  const fetchCategoryOptions = async () => {
    try {
      // Replace this URL with the actual endpoint from which you're fetching category options
      const response = await fetchAllCategory();
      const data: any[] = response.map((item: any) => ({ value: item.id, label: item.name }));
      setCategoryOptions(data);
      // Optionally, update form default values here if necessary
    } catch (error) {
      console.error('Failed to fetch category options:', error);
    }
  };

  useEffect(() => {
    fetchCategoryOptions();
  }, []);

  useEffect(() => {
    if (profile) {
      reset({
        nickname: profile.nickname,
        age: profile.age,
        gender: profile.gender,
        birthDay: profile.birthDay,
        description: profile.description,
        additionLocation: profile.additionLocation,
        cityId: profile.cityId,
        albumUrls: profile.albumUrls || [],
        interestIds: profile.interestIds || [],
        preferredGender: profile.preferredGender,
        preferredAge: profile.preferredAge,
        education: profile.education,
        occupation: profile.occupation,
        height: profile.height,
        weight: profile.weight,
      });
    }
  }, [profile, reset]);

  const onSubmit: SubmitHandler<IFormValues> = async (formValues) => {
    console.log('❤️ ~  formValues:', formValues);
    try {
      setLoading(true);
      const gender = formValues.gender === 'other' ? formValues.customGender : formValues.gender;
      const payload = {
        ...profile,
        ...formValues,
        gender: gender || 'Chưa rõ',
        albumUrls: formValues.albumUrls || [],
        preferredAge: formValues.preferredAge || 0,
      };
      await datingService.updateDatingProfile(payload as IDatingProfileModel);
      toast.success('Cập nhật thông tin thành công');
      getDatingProfile && getDatingProfile();
      setVisible(false);
    } catch (error) {
      toast.error('Không thể cập nhật tài khoản của bạn');
      setVisible(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <button
        className="button bg-black/10 text-white flex items-center gap-2 backdrop-blur-small"
        onClick={() => openEditProfile()}
      >
        Cập nhật thông tin
      </button>
      <DialogComponent
        size="xl"
        visible={visible}
        onClose={() => setVisible(false)}
        title="Cập nhật thông tin"
        placement="CENTER"
      >
        <form className="" onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col gap-6 py-5 font-bold  max-h-[80vh] overflow-y-auto divide-y divide-gray-200">
            <div className="space-y-4">
              <h5 className="font-bold"> Thông tin cơ bản </h5>
              <div className="form-group ">
                <div className="flex">
                  <label
                    htmlFor="description"
                    className="flex items-center w-[18%] text-sm text-slate-800 dark:text-slate-50 "
                  >
                    Giới thiệu
                  </label>
                  <div className="w-full">
                    <InputComponent
                      type="textarea"
                      placeholder="Giới thiệu bản thân của bạn bằng 1 vài câu hoặc 1 vài dòng để người khác biết bạn hơn.... "
                      id="description"
                      register={register('description')}
                    />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="flex">
                  <label
                    htmlFor="nickname"
                    className="flex items-center w-[18%] text-sm text-slate-800 dark:text-slate-50 "
                  >
                    Tên hiển thị
                  </label>
                  <div className="w-full">
                    <InputComponent placeholder="Tên hiển thị" id="nickname" register={register('nickname')} />
                  </div>
                </div>
              </div>
              <div className="form-group ">
                <div className="flex">
                  <label
                    htmlFor="cityId"
                    className="flex items-center w-[18%] text-sm text-slate-800 dark:text-slate-50 "
                  >
                    Thành phố
                  </label>
                  <div className="w-full flex gap-2">
                    <div className="w-full  font-normal">
                      <Select
                        className="bg-[#f1f5f9]"
                        options={cities || []}
                        onChange={(data: any) => {
                          setValue('cityId', data.value);
                        }}
                        placeholder="Thành phố"
                        value={watch('cityId')}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-group ">
                <div className="flex">
                  <label
                    htmlFor="gender"
                    className="flex items-center w-[18%] text-sm text-slate-800 dark:text-slate-50 "
                  >
                    Giới tính
                  </label>
                  <div className="w-full flex gap-2">
                    <div className="w-full  font-normal">
                      <Select
                        className="bg-[#f1f5f9]"
                        options={options}
                        onChange={(data: any) => {
                          setValue('gender', data.value);
                        }}
                        placeholder="Giới tính"
                        value={watch('gender')}
                      />
                    </div>
                    {watch('gender') === 'other' && (
                      <InputComponent placeholder="Nhập giới tính" register={register('customGender')} />
                    )}
                  </div>
                </div>
              </div>
              <div className="form-group ">
                <div className="flex">
                  <label
                    htmlFor="height"
                    className="flex items-center w-[18%] text-sm text-slate-800 dark:text-slate-50 "
                  >
                    Chiều cao
                  </label>
                  <div className="w-full">
                    <InputComponent placeholder="Chiều cao" id="height" register={register('height')} />
                  </div>
                </div>
              </div>
              <div className="form-group ">
                <div className="flex">
                  <label
                    htmlFor="weight"
                    className="flex items-center w-[18%] text-sm text-slate-800 dark:text-slate-50 "
                  >
                    Cân nặng
                  </label>
                  <div className="w-full">
                    <InputComponent placeholder="Cân nặng" id="weight" register={register('weight')} />
                  </div>
                </div>
              </div>
              <div className="form-group ">
                <div className="flex">
                  <label
                    htmlFor="education"
                    className="flex items-center w-[18%] text-sm text-slate-800 dark:text-slate-50 "
                  >
                    Địa chỉ
                  </label>
                  <div className="w-full">
                    <InputComponent
                      placeholder="Địa chỉ"
                      id="additionLocation"
                      register={register('additionLocation')}
                    />
                  </div>
                </div>
              </div>
              <div className="form-group ">
                <div className="flex">
                  <label htmlFor="age" className="flex items-center w-[18%] text-sm text-slate-800 dark:text-slate-50 ">
                    Tuổi
                  </label>
                  <div className="w-full">
                    <InputComponent placeholder="Tuổi" id="age" register={register('age')} />
                  </div>
                </div>
              </div>

              <div className="form-group ">
                <div className="flex">
                  <label
                    htmlFor="birthDay"
                    className="flex items-center w-[18%] text-sm text-slate-800 dark:text-slate-50 "
                  >
                    Ngày sinh
                  </label>
                  <div className="w-full">
                    <InputComponent placeholder="Ngày sinh" id="birthDay" register={register('birthDay')} />
                  </div>
                </div>
              </div>
            </div>
            <div className="space-y-4 pt-4">
              <h5 className="font-bold"> Nơi làm việc và học vấn </h5>
              <div className="form-group ">
                <div className="flex">
                  <label
                    htmlFor="occupation"
                    className="flex items-center w-[18%] text-sm text-slate-800 dark:text-slate-50 "
                  >
                    Nghề nghiệp
                  </label>
                  <div className="w-full">
                    <InputComponent placeholder="Nghề nghiệp" id="occupation" register={register('occupation')} />
                  </div>
                </div>
              </div>
              <div className="form-group ">
                <div className="flex">
                  <label
                    htmlFor="education"
                    className="flex items-center w-[18%] text-sm text-slate-800 dark:text-slate-50 "
                  >
                    Học vấn
                  </label>
                  <div className="w-full">
                    <InputComponent placeholder="Học vấn" id="education" register={register('education')} />
                  </div>
                </div>
              </div>
            </div>
            <div className="space-y-4 pt-4">
              <h5 className="font-bold"> Lối sống </h5>
              <div className="form-group ">
                <div className="flex">
                  <label
                    htmlFor="categoryFavorites"
                    className="flex items-center w-[18%] text-sm text-slate-800 dark:text-slate-50 "
                  >
                    Sở thích
                  </label>
                  <div className="w-full flex gap-2">
                    <ReactSelect
                      isMulti
                      options={categoryOptions}
                      onChange={(selectedOptions: MultiValue<any>) => {
                        setValue(
                          'interestIds',
                          selectedOptions.map((option) => option.value),
                        );
                      }}
                      placeholder="Sở thích"
                      value={categoryOptions.filter((option) => {
                        if (watch('interestIds')) {
                          return watch('interestIds').includes(option.value);
                        }
                        return false;
                      })}
                      styles={{
                        container: (provided) => ({
                          ...provided,
                          width: '100%',
                        }),
                        control: (provided) => ({
                          ...provided,
                          width: '100%',
                        }),
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="space-y-4 pt-4">
              <h5 className="font-bold"> Xu hướng </h5>
              <div className="form-group ">
                <div className="flex">
                  <label
                    htmlFor="gender"
                    className="flex items-center w-[18%] text-sm text-slate-800 dark:text-slate-50 "
                  >
                    Xu hướng giới tính
                  </label>
                  <div className="w-full flex gap-2">
                    <div className="w-full  font-normal">
                      <Select
                        className="bg-[#f1f5f9]"
                        options={optionsPreferredGender}
                        onChange={(data: any) => {
                          setValue('preferredGender', data.value);
                        }}
                        placeholder="Xu hướng giới tính"
                        value={watch('preferredGender')}
                      />
                    </div>
                    {/* {watch('preferredGender') === 'other' && (
                      <InputComponent placeholder="Nhập giới tính" register={register('customGender')} />
                    )} */}
                  </div>
                </div>
              </div>
              <div className="form-group ">
                <div className="flex">
                  <label
                    htmlFor="preferredAge"
                    className="flex items-center w-[18%] text-sm text-slate-800 dark:text-slate-50 "
                  >
                    Xu hướng tuổi
                  </label>
                  <div className="w-full">
                    <InputComponent
                      placeholder="Để hiểu rõ xu hướng tuổi mà bạn mong muốn"
                      id="preferredAge"
                      register={register('preferredAge')}
                      type="number"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="form-group flex justify-end items-center">
            <ButtonSecondary className="mr-4" onClick={() => setVisible(false)}>
              Hủy bỏ
            </ButtonSecondary>
            <ButtonPrimary loading={false}>Cập nhật</ButtonPrimary>
          </div>
        </form>
      </DialogComponent>
    </>
  );
};

export default EditProfile;
