import { Tooltip } from 'antd';
import { ImageUp, Trash } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import DialogComponent from '../../../components/dialog/dialog.component';
import { useDropzone } from 'react-dropzone';
import { fetchUrlImages, fetchUrlVideos } from '../../../api';
import ButtonPrimary from '../../../components/button-refactor/button-primary';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';

function renderImage(files, preview, handleClearImage) {
  return (
    <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 4, 1024: 5 }}>
      <Masonry gutter="12px">
        {files &&
          files?.map((file, index) => {
            return (
              <button key={index} className="relative block rounded-md h-full group">
                <div className="absolute h-full top-0 right-0 w-full bg-transparent group-hover:bg-[rgba(0,0,0,.3)] rounded-md transition-all duration-300">
                  <div
                    onClick={() => handleClearImage(file, preview[index])}
                    className="absolute cursor-pointer rounded-md right-2 top-2 text-slate-800 w-6 h-6
                                flex justify-center items-center bg-slate-50"
                  >
                    <Trash size={14} />
                  </div>
                </div>

                <LazyLoadImage
                  className={`${'test'} rounded-md object-cover w-full h-full shadow-md `}
                  src={preview[index]}
                  alt={preview[index]}
                  //   onClick={() => {
                  //     if (exampleImages.length < 3) {
                  //     return;
                  //   }
                  //   setIndexViewImage(index);
                  //   setVisiblePreview(true);
                  // }}
                />
              </button>
            );
          })}
      </Masonry>
    </ResponsiveMasonry>
  );
}

const Upload = ({ handleUploadImage }: { handleUploadImage: (urls: string[]) => void }) => {
  const [visible, setVisible] = useState(false);
  const [preview, setPreview] = useState<string[]>([]);
  const [files, setFiles] = useState<File[]>([]);
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [imageUrls, setImageUrls] = useState<string[]>([]);
  const [videoUrls, setVideoUrls] = useState<string[]>([]);

  const onDrop = (acceptedFiles: File[]) => {
    const previewUrls = acceptedFiles.map((file) => {
      return URL.createObjectURL(file);
    });

    setFiles((prev) => [...prev, ...acceptedFiles]);
    setPreview([...preview, ...previewUrls]);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
  });

  const handleClearImage = (file, url) => {
    setFiles((prev) => prev.filter((item) => item.name !== file.name));
    setPreview((prev) => prev.filter((item) => item !== url));
  };

  const handleGetUrlFileImage = async (item) => {
    const formData = new FormData();
    formData.append('type', item);
    const res = await fetchUrlImages(formData);
    if (res.length) {
      res.forEach((img) => {
        setImageUrls((prev) => [...prev, img?.url]);
      });
    }
  };

  const handleGetUrlFileVideo = async (item) => {
    const formData = new FormData();
    formData.append('type', item);
    const res = await fetchUrlVideos(formData);
    if (res.length > 0) {
      res.forEach((video) => {
        setVideoUrls([...videoUrls, video?.url]);
      });
    }
  };

  const onConfirm = () => {
    handleUploadImage([...imageUrls, ...videoUrls]);
    setVisible(false);
  };

  useEffect(() => {
    const handleUploadImages = async () => {
      try {
        setLoadingUpload(true);
        const handles = files.map((item) => {
          if (item?.type.startsWith('image')) {
            return handleGetUrlFileImage(item);
          }
          if (item?.type.startsWith('video')) {
            return handleGetUrlFileVideo(item);
          }
          return null;
        });

        await Promise.all(handles);
      } catch (error) {
        //
      } finally {
        setLoadingUpload(false);
      }
    };

    handleUploadImages();
  }, [files.length]);

  return (
    <>
      <Tooltip color="blue" placement="bottom" title="Tải ảnh lên">
        <Button onClick={() => setVisible(true)} />
      </Tooltip>
      <DialogComponent
        size="lg"
        visible={visible}
        onClose={() => setVisible(false)}
        title="Tải ảnh lên"
        placement="CENTER"
      >
        <div className="relative w-full h-full mt-4">{renderImage(files, preview, handleClearImage)}</div>
        <div className="flex items-center justify-center w-full mt-4">
          <label
            htmlFor="dropzone-file"
            className="flex flex-col items-center justify-center w-full h-30 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-gray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500"
          >
            <div className="flex flex-col items-center justify-center pt-5 pb-6">
              <Icon />
              <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                <span className="font-semibold">Click to upload</span> or drag and drop
              </p>
            </div>
            <div {...getRootProps()}>
              <input {...getInputProps()} id="dropzone-file" type="file" accept="image/*" multiple />
            </div>
          </label>
        </div>
        <ButtonPrimary loading={loadingUpload} onClick={onConfirm} className="w-full mt-4 flex justify-center">
          Tải lên
        </ButtonPrimary>
      </DialogComponent>
    </>
  );
};

const Button = ({ onClick }: { onClick: () => void }) => (
  <button
    type="button"
    className="custom-target-icon inline-block rounded-full mr-3 font-bold text-center uppercase align-middle transition-all cursor-pointer bg-fuchsia-500/0 text-xs ease-soft-in tracking-tight-soft bg-150 bg-x-25 hover:bg-blue-500/25 hover:scale-102 active:bg-fuchsia/45 text-black px-1"
    onClick={onClick}
  >
    <ImageUp size={24} className="" />
  </button>
);

const Icon = () => (
  <svg
    className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 16"
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
    />
  </svg>
);

export default Upload;
