import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { fetchUsersApi } from '../../../api/admin/user.api';

export const useUserHook = ({ pageNumber, isRefetch }: { pageNumber?: number; isRefetch?: boolean }) => {
  const [users, setUsers] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  useEffect(() => {
    getUsers(pageNumber);
  }, [pageNumber, isRefetch]);
  const getUsers = async (pageNumber) => {
    try {
      setLoading(true);
      const { data } = await fetchUsersApi(pageNumber);
      setUsers(data);
    } catch {
      //
    } finally {
      setLoading(false);
    }
  };
  return {
    data: users,
    loading,
  };
};
