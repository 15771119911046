import { API } from '..';

export const fetchAllReqDonateAPI = async () => {
  return new Promise<any>((resolve, reject) => {
    API.get(`/v1/user-service/admin/profile/get-all-request`)
      .then((res) => {
        resolve(res);
      })
      .catch(reject);
  });
};
export const approveReqDonateAPI = async (id: string) => {
  return new Promise<any>((resolve, reject) => {
    API.put(`/v1/user-service/admin/profile/approve-recive-donate-request/${id}`)
      .then((res) => {
        resolve(res);
      })
      .catch(reject);
  });
};
export const rejectReqDonateAPI = async (id: string) => {
  return new Promise<any>((resolve, reject) => {
    API.put(`/v1/user-service/admin/profile/reject-recive-donate-request/${id}`)
      .then((res) => {
        resolve(res);
      })
      .catch(reject);
  });
};
