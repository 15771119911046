import React, { useState } from 'react';
import { IUserProfile } from '../../../models';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import Avatar from '../../../components/avatar/avatar.component';
import EditProfile from './edit-profile';
import { IDatingProfileModel } from '../../../models/dating.model';
import { IOption } from '../../../select/select.component';
import { fetchUrlImages } from '../../../api';
import { datingService } from '../../../services/dating.service';
import toast from 'react-hot-toast';

interface ProfileBannerProps {
  profile?: IDatingProfileModel;
  changeTab?: any;
  tabActive?: any;
  cities?: IOption[];
  getDatingProfile?: () => void;
  totalMatchRequests?: number;
}

const Tabs = [
  {
    label: 'Khám phá',
    value: 'connection',
  },
  {
    label: 'Kết nối ',
    value: 'requests',
  },
  {
    label: 'Profile',
    value: 'profile',
  },
  {
    label: 'Album ảnh',
    value: 'album',
  },
];

const ProfileBanner = ({
  profile,
  changeTab,
  tabActive,
  cities,
  getDatingProfile,
  totalMatchRequests,
}: ProfileBannerProps) => {
  const navigate = useNavigate();

  const onUploadAvatar = async (files: any) => {
    try {
      const file = files[0];
      const formData = new FormData();
      formData.append('type', file);
      const res = await fetchUrlImages(formData);
      console.log('❤️ ~ onUploadAvatar ~ res:', res);
      profile && (profile.avatar = res[0].url);
      profile && (profile.albumUrls = [res[0].url]);
      await datingService.updateDatingProfile(profile as IDatingProfileModel);
      toast.success('Cập nhật thông tin thành công');
      getDatingProfile && getDatingProfile();
    } catch (error) {
      toast.error('Không thể cập nhật ảnh đại diện');
      console.error('Error uploading avatar:', error);
    }
  };

  return (
    <div className="bg-white border shadow lg:rounded-2xl dark:bg-dark2">
      <div className="relative overflow-hidden w-full lg:h-56 h-48">
        <img
          src={
            profile?.coverImage ||
            'https://wallpapers.com/images/hd/pink-love-1920-x-1080-wallpaper-mzjkazybbea7amzt.jpg'
          }
          alt=""
          className="h-full w-full object-cover inset-0 rounded-2xl"
        />
        <div className="w-full bottom-0 absolute left-0 bg-gradient-to-t from-black/60 pt-20 z-10" />
        <div className="absolute bottom-0 right-0 m-4 z-20">
          <EditProfile getDatingProfile={getDatingProfile} cities={cities} profile={profile} />
        </div>
      </div>
      <div>
        <div className="flex flex-col justify-center  md:items-center lg:-mt-50 -mt-28">
          <div className="relative z-10">
            <Avatar
              avatar={_.get(profile, 'avatar', '')}
              label={_.get(profile, 'nickname', '')}
              size="xxl"
              uploaded
              onUploadAvatar={onUploadAvatar}
              // onUploadSuccess={onUpdateSuccess}
              preview={false}
            />
          </div>
          <div
            className={`mt-2 px-3 text-base  dark:text-white flex items-center ${
              _.get(profile, 'nickname') ? 'font-bold text-black md:text-3xl' : 'md:text-1xl text-grey-400'
            }`}
          >
            {_.get(profile, 'nickname', '( Vui lòng cập nhật tên hiển thị )')}
          </div>
        </div>
      </div>
      <div className="flex items-center justify-between mt-3 border-t border-gray-100 max-lg:flex-col dark:border-slate-700">
        <nav className="flex items-center gap-0.5 w-full -mb-px  text-gray-600 font-medium text-[15px]  dark:text-white max-md:w-full max-md:overflow-x-auto px-4">
          {Tabs.map((tab) => (
            <div
              key={tab.value}
              className={` flex items-center cursor-pointer   px-3 py-3 ${
                tabActive === tab.value && 'bg-white border-b-2 border-[#E31777] font-bold'
              } `}
              onClick={() => changeTab && changeTab(tab.value)}
            >
              <div>
                {tab.label} {tab.value === 'requests' && <span className="text-[#E31777]">({totalMatchRequests})</span>}
              </div>
            </div>
          ))}
        </nav>
      </div>
    </div>
  );
};

export default ProfileBanner;
