import React, { useEffect, useState } from 'react';
import { Card, Avatar, Typography } from 'antd';
import { PlusOutlined, CloseOutlined, DollarOutlined } from '@ant-design/icons';
import { ICreator } from '../../types/creator';
import followerIcon from '../../assets/icons/follower.png';
import { useFollow } from '../../hooks/useFollow/useFollow';

import ButtonPrimary from '../button-refactor/button-primary';
import ButtonSecondary from '../button-refactor/button-secondary';
import { formatCurrencyShortend, formatFollowers } from '../../utils/convertData.util';

const { Text } = Typography;

export interface ICreatorProps {
  creator: ICreator;
  onSuccess?: () => void;
  onClick?: () => void;
  removeSuggestion?: (userId: string) => void;
}

const Creator: React.FC<ICreatorProps> = ({ creator, onSuccess, onClick, removeSuggestion }) => {
  const [fallbackImage, setFallbackImage] = useState<string>('');
  const { handleFollow, handleUnFollow } = useFollow();

  const handleFollowAction = async () => {
    const actionFn = creator.isFollow ? handleUnFollow : handleFollow;
    await actionFn(creator.userId);
    onSuccess?.();
  };

  useEffect(() => {
    if (creator.avatar === null || creator.coverImage === null) {
      setFallbackImage('https://www.w3schools.com/howto/img_avatar.png');
    }
  }, [creator.avatar, creator.coverImage]);

  return (
    <Card
      hoverable
      className="min-w-36 relative bg-white shadow-sm rounded-lg overflow-hidden"
      bodyStyle={{ padding: 0 }}
    >
      {removeSuggestion && (
        <CloseOutlined
          onClick={() => removeSuggestion(creator.userId)}
          className="absolute top-4 right-4 text-white cursor-pointer hover:text-gray-700 p-2 z-10 rounded-full bg-black opacity-50"
        />
      )}
      {creator.coverImage ? (
        <img src={creator.coverImage} alt="cover" className="w-full h-20" />
      ) : (
        <div className="w-full h-20 bg-gray-200"> </div>
      )}
      <div className="h-full flex flex-col relative p-4">
        <Avatar
          alt="avatar"
          src={creator.avatar === null ? fallbackImage : creator.avatar}
          size={80}
          className="absolute left-2 -top-[50px]  border-4 border-white"
          onClick={onClick}
        />
        <div className="mt-4 flex gap-1 items-center">
          <Text className="text-lg font-bold text-gray-800 truncate block whitespace-nowrap" onClick={onClick}>
            {creator.fullname}
          </Text>{' '}
          |<Text className="text-sm text-gray-600 truncate block whitespace-nowrap p-0">{creator.username}</Text>
        </div>
        <div className="flex flex-col">
          <div className="flex items-center mb-2">
            <img src={followerIcon} alt="follower" className="w-3 h-3 mr-2" />
            <Text className="text-sm text-gray-600 block">{formatFollowers(creator.totalFollow)} người theo dõi</Text>
          </div>
          <div className="flex items-center mb-2">
            <DollarOutlined className="text-yellow-500 mr-1" />
            <Text className="text-sm text-gray-600 block">{formatCurrencyShortend(100000023)} donated</Text>
          </div>
        </div>

        {creator.isFollow ? (
          <ButtonSecondary onClick={handleFollowAction} className="rounded-full">
            Bỏ theo dõi
          </ButtonSecondary>
        ) : (
          <ButtonPrimary onClick={handleFollowAction} className="rounded-full flex items-center justify-center gap-1">
            <PlusOutlined className="text-white " />
            Theo dõi
          </ButtonPrimary>
        )}
      </div>
    </Card>
  );
};

export default Creator;
