import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../configuration';
import { Post } from '../../components';
import Loading from '../../components/loading/loading.component';
import useBookmark from '../../hooks/use-bookmark/useBookMark';
import { BookmarkCard } from '../../components/bookmark';
import toast from 'react-hot-toast';
import InfiniteScroll from 'react-infinite-scroll-component';

export const SavedPostsPage = () => {
  const { loading, bookmarkedPosts, getBookmarkedPosts, toggleBookmark, hasMore } = useBookmark();
  const profileUser = useSelector((state: RootState) => state.features.auth.profile);
  const [page, setPage] = useState(1);

  useEffect(() => {
    if (profileUser?.userId) {
      getBookmarkedPosts(profileUser.userId, 10, page);
    }
  }, [profileUser, getBookmarkedPosts, page]);

  const loadMore = () => {
    setPage((prev) => prev + 1);
  };

  if (loading && hasMore) {
    return <Loading classname="mx-auto" />;
  }

  return (
    <div className="max-w-7xl mx-auto">
      <h1 className="text-2xl font-bold mb-6 text-slate-800 dark:text-white">Bài viết đã lưu</h1>

      {bookmarkedPosts.length === 0 ? (
        <div className="text-center py-8">
          <p className="text-gray-500 dark:text-gray-400">Bạn chưa lưu bài viết nào</p>
        </div>
      ) : (
        <InfiniteScroll
          dataLength={bookmarkedPosts.length}
          next={loadMore}
          hasMore={hasMore}
          loader={<Loading classname="mx-auto my-4" />}
        >
          <div className="space-y-6">
            {bookmarkedPosts.map((post) => (
              <BookmarkCard
                key={post.id}
                post={post}
                onToggleBookmark={(postId) => {
                  toggleBookmark(postId).then(() => {
                    getBookmarkedPosts(profileUser?.userId || '', 10, page);
                    toast.success('Đã bỏ lưu bài viết');
                  });
                }}
              />
            ))}
          </div>
        </InfiniteScroll>
      )}
    </div>
  );
};
