import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import TinderCard from 'react-tinder-card';
import heartIcon from '../../../assets/icons/heart.png';
import crossIcon from '../../../assets/icons/cross.png';
import { useDating } from '../../../hooks/use-dating/useDating';
import { IDatingProfileModel } from '../../../models/dating.model';
import { CircleUser, UserRoundCheck } from 'lucide-react';
import { MdOutlineInterests, MdOutlineNaturePeople } from 'react-icons/md';
import { FaBriefcase, FaCalendarDay, FaCity, FaGraduationCap, FaUserCircle } from 'react-icons/fa';
// import Skeleton from 'react-loading-skeleton';
// import 'react-loading-skeleton/dist/skeleton.css';
import DialogComponent from '../../../components/dialog/dialog.component';
import { datingService } from '../../../services/dating.service';
import Profile from './profile';
import Albums from './albums';
import toast from 'react-hot-toast';

interface TinderCardWithSwipe {
  swipe: (dir?: 'left' | 'right') => Promise<void>;
  restoreCard: () => Promise<void>;
}

const RenderInfoField = ({ label, value, icon }: { label: string; value: string | number; icon: React.ReactNode }) => {
  return (
    <div className="flex gap-2 items-center">
      <div>{icon}</div>
      <span className="text-nowrap">{label}:</span>
      <span className="font-semibold text-black dark:text-white max-h-52  truncate">{value || 'Chưa có'}</span>
    </div>
  );
};

const Connection = () => {
  const { userDatingProfiles, getUserDatingProfiles, isLoading, datingProfile: myProfile } = useDating();
  const [visible, setVisible] = useState(false);
  const [profile, setProfile] = useState<IDatingProfileModel>();
  const [currentProfileId, setCurrentProfileId] = useState('');
  const [people, setPeople] = useState<IDatingProfileModel[]>([]);
  const [currentIndex, setCurrentIndex] = useState(people.length - 1);
  const [lastDirection, setLastDirection] = useState('');
  const [isOutOfFrame, setIsOutOfFrame] = useState(false);

  const isOutOfPeople = useMemo(() => {
    return people.length === 0 && currentIndex === people.length - 1 && !!currentProfileId;
  }, [people, currentIndex, currentProfileId]);

  useEffect(() => {
    getUserDatingProfiles();
  }, []);

  useEffect(() => {
    if (_.isEmpty(userDatingProfiles)) return;

    setPeople(_.get(userDatingProfiles, 'data', []));
    setCurrentIndex(_.get(userDatingProfiles, 'data', []).length - 1);
  }, [userDatingProfiles]);

  const sendConnection = useCallback(async () => {
    // profileId: string, direction: 'left' | 'right'
    if (!myProfile) return;
    if (!isOutOfFrame) return;
    if (lastDirection === 'left') {
      toast.error('Không thích', { position: 'top-center' });
    }
    if (lastDirection === 'right') {
      await datingService.sendMatchRequest({
        receivedProfileId: currentProfileId,
        requestProfileId: myProfile?.profileId,
      });
      toast.success('Đã gửi kết nối', { position: 'top-center' });
    }
  }, [lastDirection, isOutOfFrame, currentProfileId]);

  useEffect(() => {
    sendConnection();
  }, [sendConnection]);

  const canGoBack = currentIndex < people.length - 1;

  const currentIndexRef = useRef(currentIndex);

  const canSwipe = currentIndex >= 0;

  const childRefs = useMemo(
    () =>
      Array(people.length)
        .fill(0)
        .map(() => React.createRef<TinderCardWithSwipe>()),
    [people],
  );

  const swiped = (direction: string, nameToDelete: string, index: number) => {
    setLastDirection(direction);
    setIsOutOfFrame(false);
    updateCurrentIndex(index - 1);
  };

  const updateCurrentIndex = (val: number) => {
    setCurrentIndex(val);
    currentIndexRef.current = val;
  };
  const outOfFrame = (profileId: string, idx: number) => {
    setPeople((prev) => prev.filter((item) => item.profileId !== profileId));
    setIsOutOfFrame(true);
    setCurrentProfileId(profileId);
    if (currentIndexRef.current >= idx) {
      childRefs[idx].current?.restoreCard();
    }
  };

  const swipe = async (dir: string) => {
    if (canSwipe && currentIndex < people.length) {
      setIsOutOfFrame(false);
      await childRefs[currentIndex].current?.swipe(dir as 'left' | 'right');
    }
  };

  const goBack = async () => {
    if (!canGoBack) return;
    const newIndex = currentIndex + 1;
    updateCurrentIndex(newIndex);
    await childRefs[newIndex].current?.restoreCard();
  };

  const openViewProfile = (profile: IDatingProfileModel) => {
    setProfile(profile);
    setVisible(true);
  };

  return (
    <div className="mt-8 text-sm font-medium dark:bg-dark2 p-4 select-none">
      <div className="flex justify-center md:min-h-[600px] min-h-[520px] relative">
        {/* Added relative positioning */}
        <div className="relative md:w-[400px] w-full h-96">
          {isOutOfPeople && (
            <div className="absolute inset-0 flex items-center justify-center">Vui lòng quay lại sau bạn nhé !</div>
          )}
          {_.map(people, (item, index) => {
            return (
              <TinderCard
                ref={childRefs[index]}
                key={index}
                className="absolute inset-0 " // Position absolute here
                preventSwipe={['up', 'down']}
                onSwipe={(dir) => swiped(dir, item.profileId, index)}
                onCardLeftScreen={() => outOfFrame(item.profileId, index)}
              >
                <div
                  style={{ zIndex: people.length - index }}
                  className="w-full shadow-lg rounded-lg cursor-pointer bg-white "
                >
                  <div
                    style={{
                      backgroundImage: `url(${
                        _.get(item, 'avatar') || 'https://www.w3schools.com/howto/img_avatar.png'
                      })`,
                    }}
                    className="w-full h-72 rounded-lg bg-cover bg-center"
                  />
                  <div className="md:py-2 py-4 px-6 ">
                    <h2 className="text-xl font-semibold mb-2">{item.nickname}</h2>
                    <div className="flex flex-col gap-4">
                      <RenderInfoField label="Giới thiệu" value={item.description} icon={<CircleUser size={20} />} />
                      <div className="flex gap-2 justify-between">
                        <RenderInfoField label="Tuổi" value={item.age} icon={<FaCalendarDay size={20} />} />
                        <RenderInfoField
                          label="Giới tính"
                          value={item.gender}
                          icon={<MdOutlineNaturePeople size={20} />}
                        />
                      </div>
                      <RenderInfoField label="Tỉnh/Thành phố" value={item.city} icon={<FaCity size={20} />} />
                      <RenderInfoField
                        label="Sở thích"
                        value={item.interests.join(', ')}
                        icon={<MdOutlineInterests size={20} />}
                      />
                      <div className="flex gap-2 justify-between">
                        <RenderInfoField label="Nghề nghiệp" value={item.occupation} icon={<FaBriefcase size={20} />} />
                        <RenderInfoField label="Học vấn" value={item.education} icon={<FaGraduationCap size={20} />} />
                      </div>
                    </div>
                  </div>
                  <div className="justify-between py-2 px-4 hidden md:flex">
                    <Button onClick={() => swipe('left')} direction="left" />
                    <ButtonViewProfile profile={item} onClick={() => openViewProfile(item)} />
                    <Button onClick={() => swipe('right')} direction="right" />
                  </div>
                </div>
              </TinderCard>
            );
          })}
        </div>
      </div>
      <div className="flex justify-between mt-6 md:hidden">
        <Button onClick={() => swipe('left')} direction="left" />
        <ButtonViewProfile profile={people[currentIndex]} onClick={() => openViewProfile(people[currentIndex])} />
        <Button onClick={() => swipe('right')} direction="right" />
      </div>
      <DialogComponent size="xl" visible={visible} onClose={() => setVisible(false)} title="" placement="CENTER">
        <div className="max-h-[600px] overflow-y-auto">
          <Albums disableUpload profile={profile} />
          <Profile profile={profile} />
        </div>
      </DialogComponent>
    </div>
  );
};

// const Loading = () => {
//   return (
//     <>
//       <Skeleton className="h-72" />
//       <Skeleton height={50} />
//     </>
//   );
// };

const Button = ({ direction, onClick }: { direction: 'left' | 'right'; onClick: () => void }) => {
  return (
    <button className=" w-14 h-14 flex items-center justify-center  rounded-full" onClick={onClick}>
      {direction === 'right' ? <IconHeart /> : <IconCross />}
    </button>
  );
};

const ButtonViewProfile = ({ profile, onClick }: { profile: IDatingProfileModel; onClick: () => void }) => {
  return (
    <button className=" w-14 h-14 flex items-center justify-center rounded-full bg-green-500" onClick={onClick}>
      <FaUserCircle size={70} className="text-white" />
    </button>
  );
};

const IconHeart = () => {
  return <img src={heartIcon} alt="heart" className="w-full h-full rounded-full" />;
};

const IconCross = () => {
  return <img src={crossIcon} alt="cross" className="w-full h-full rounded-full" />;
};

export default Connection;
