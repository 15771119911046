import React, { useEffect, useState } from 'react';
import GroupBanner from './components/group-banner.component';
import { useGroupHook } from '../../hooks/group/useGroup';
import { useParams } from 'react-router-dom';
import { useHashTag } from '../../hooks/hashtag/useHashtag';
import { GroupPermissions } from '../../constants/group.constant';
import TimelineTab from './components/tabs/timeline-tab.component';
import MemberListTab from './components/tabs/member-list-tab.component';
import { MdOutlineLock, MdOutlinePublic } from 'react-icons/md';
import { GoPeople } from 'react-icons/go';
import SettingTab from './components/tabs/setting-tab.component';

const GroupDetail = () => {
  // get parms from url
  const { groupId } = useParams<{ groupId: string }>();
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageMemberNumber, setPageMemberNumber] = useState<number>(1);
  const [pageMemberRequestNumber, setPageMemberRequestNumber] = useState<number>(1);
  const [isRefetch, setIsRefetch] = useState<boolean>(false);

  const { detail, permission, members, memberRequest } = useGroupHook({
    groupId,
    pageMemberNumber,
    pageMemberRequestNumber,
    isRefetch,
  });

  const { getHashTags } = useHashTag();
  const tabs = [
    { name: 'Thảo luận', key: 'thao-luan', isShow: true },
    { name: 'Thành viên', key: 'thanh-vien', isShow: true },
    { name: 'Ảnh của nhóm', key: 'anh-cua-nhom', isShow: true },
    { name: 'Giới thiệu', key: 'gioi-thieu', isShow: true },
    { name: 'Thiết lập', key: 'thiet-lap', isShow: permission === GroupPermissions.GROUP_ADMIN },
  ];
  const [activeTab, setActiveTab] = useState<string>(tabs[0].key);

  return (
    <div className="max-w-[1065px] mx-auto">
      {detail && <GroupBanner permission={permission} data={detail} />}
      {permission ? (
        <>
          <div className="lg:rounded-b-2xl bg-white shadow  flex items-center justify-between  border-t border-gray-100 px-2 dark:border-slate-700">
            <div className="flex gap-0.5 rounded-xl overflow-hidden -mb-px text-gray-500 font-medium text-sm overflow-x-auto dark:text-white">
              {tabs.map(
                (tab) =>
                  tab.isShow && (
                    <div
                      key={tab.key}
                      className={`inline-block py-3 leading-8 px-3.5 cursor-pointer ${
                        activeTab === tab.key ? 'border-b-2 border-blue-600 text-blue-600' : 'text-gray-500'
                      }`}
                      onClick={() => setActiveTab(tab.key)}
                    >
                      {tab.name}
                    </div>
                  ),
              )}
            </div>
            <div className="flex items-center gap-1 text-sm p-3 bg-secondery py-2 mr-2 rounded-xl max-md:hidden dark:bg-white/5">
              <input placeholder="Search .." className="!bg-transparent" />
            </div>
          </div>
          {activeTab === 'thao-luan' && <TimelineTab getHashTags={getHashTags} detail={detail} />}
          {activeTab === 'thanh-vien' && (
            <MemberListTab detail={detail} permission={permission} members={members} memberRequest={memberRequest} />
          )}
          {activeTab === 'thiet-lap' && <SettingTab data={detail} refetchData={() => setIsRefetch(!isRefetch)} />}
        </>
      ) : (
        <>
          {' '}
          <div className="mt-4">
            <div className="lg:space-y-4 lg:pb-8 max-lg:grid sm:grid-cols-2 max-lg:gap-6 uk-sticky uk-active uk-sticky-fixed">
              {' '}
              <div className="box p-5 px-6">
                <div className="flex items-ce justify-between text-black dark:text-white">
                  <h3 className="font-bold text-lg"> Giới thiệu </h3>
                </div>

                <ul className="text-gray-700 space-y-4 mt-4 text-sm dark:text-white/80">
                  <li>{detail?.groupDescription}</li>
                  <li className="flex items-start gap-3">
                    {detail?.privacySettings !== 'private' ? (
                      <MdOutlinePublic size={24} />
                    ) : (
                      <MdOutlineLock size={24} />
                    )}
                    <div>
                      <span className="font-semibold text-black dark:text-white uppercase">
                        {detail?.privacySettings !== 'private' ? 'Riêng tư' : 'Công khai'}
                      </span>
                      <p> Bất kỳ ai cũng có thể thấy ai trong nhóm và những gì họ đăng.</p>
                    </div>
                  </li>

                  <li className="flex items-center gap-3">
                    <GoPeople size={20} />
                    <div>
                      Thành viên{' '}
                      <span className="font-semibold text-black dark:text-white"> {detail?.memberCount}</span>{' '}
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default GroupDetail;
