import React, { useEffect, useState } from 'react';
import { useHashTag } from '../../hooks/hashtag/useHashtag';
import AsideRight from './components/aside-right';
import { Helmet } from 'react-helmet-async';
import Profile from './components/profile';
import ProfileBanner from './components/profile-banner';
import { IOption } from '../../select/select.component';
import { fetchAllCity } from '../../api';
import _ from 'lodash';
import { useDating } from '../../hooks/use-dating/useDating';
import Connection from './components/connection';
import Albums from './components/albums';
import Requests from './components/requests';

export const DatingPage = () => {
  const { hashtags, getHashTags } = useHashTag();
  const [tabActive, setTabActive] = useState('connection');
  const [cities, setCities] = useState<IOption[]>([]);
  const { datingProfile, getDatingProfile, getMatchRequestsByProfileId, matchRequests } = useDating();

  const changeTab = (tab: string) => {
    setTabActive(tab);
  };

  const fetchCityOptions = async () => {
    try {
      // Replace this URL with the actual endpoint from which you're fetching category options
      const response = await fetchAllCity();
      const data: any = _.map(_.get(response, 'countries[0].cities', []), (item: any) => ({
        value: item.cityId,
        label: item.name,
      }));
      setCities(data);
      // Optionally, update form default values here if necessary
    } catch (error) {
      console.error('Failed to fetch city options:', error);
    }
  };

  useEffect(() => {
    fetchCityOptions();
  }, []);

  useEffect(() => {
    if (datingProfile) {
      setTabActive('connection');
      if (datingProfile.profileId) {
        getMatchRequestsByProfileId(datingProfile.profileId);
      }
    }
  }, [datingProfile]);
  // mx-auto md:max-w-4xl max-w-6xl

  const refetchMatchRequests = () => {
    if (datingProfile?.profileId) {
      getMatchRequestsByProfileId(datingProfile.profileId);
    }
  };

  return (
    <div className=" h-100">
      <Helmet>
        <title>Cab VN</title>
        <meta name="home" content="Cab VN" />
      </Helmet>
      <div className="mx-auto md:max-w-6xl max-w-6x">
        <div className="grid md:grid-cols-8 grid-cols-1 gap-4">
          <div className="md:col-span-5 col-span-1">
            <main className="w-full">
              <div className="mb-8">
                <ProfileBanner
                  getDatingProfile={getDatingProfile}
                  cities={cities}
                  tabActive={tabActive}
                  changeTab={changeTab}
                  profile={datingProfile}
                  totalMatchRequests={matchRequests?.length}
                />
                {tabActive === 'profile' && <Profile profile={datingProfile} />}
                {tabActive === 'connection' && <Connection />}
                {tabActive === 'album' && <Albums profile={datingProfile} getDatingProfile={getDatingProfile} />}
                {tabActive === 'requests' && <Requests matchRequests={matchRequests} refetch={refetchMatchRequests} />}
              </div>
            </main>
          </div>
          <div className="md:col-span-3 col-span-1">
            <AsideRight />
          </div>
        </div>
      </div>
    </div>
  );
};
