export const ensureMinDelay = async <T>(promise: Promise<T>, millisecond: number = 1000) => {
  const delayPromise = new Promise<void>((resolve) => {
    setTimeout(() => {
      resolve();
    }, millisecond);
  });

  const response = await Promise.all([promise, delayPromise]);
  return response[0];
};
