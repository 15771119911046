export const convertPostCategory = (id: string) => {
  switch (id) {
    case 'f6391e4c-21b1-46fc-a369-f10f7dfdea1c':
      return 'Nhạc';
    case '79fe7f9d-4e5e-4acd-bfb1-b10104a4918e':
      return 'Làm đẹp';
    case '05c0b20b-c7f9-4a56-828e-14a53096b6fc':
      return 'Công Việc';
    case '6c1f741f-621b-4134-b6bc-7c23a81f1df4':
      return 'Sở thích';
    case '645f15e6-990c-43b7-9f62-b0812c573195':
      return 'Sách';
    case '33583b3e-3c94-42dd-878c-4e83762779c9':
      return 'Du lịch';
    case 'f887bcd1-ce67-4288-804a-ffd629ffa21b':
      return 'Thể thao';
    case 'f8d887ec-2634-49a7-8050-994ab58849bd':
      return 'Giải trí tổng hợp';
    case 'eeeef7bd-12a9-4c6b-9cd6-2ecff6b77c1f':
      return 'Công nghệ';
    case 'e68077a6-81f7-4f38-b0bf-2bbca9f45c05':
      return 'Thú cưng';
    case '68a0ebe4-7188-41fe-9298-ae9f60af4e83':
      return 'Game';
    case 'e207679d-027d-4501-9f7c-ecefe885341b':
      return 'Phim';
    case 'fb4775f9-4d5d-4cc5-98e5-78345350cd1e':
      return 'Sáng tạo';
    case '49528e7d-ab2e-4cd8-9bda-c10ed75cb1ad':
      return 'NSFW';
    case '9a7554e8-c14e-4cc8-984b-9736b60275b8':
      return 'Anime';
    case '17955cd0-4bf8-47ef-b9eb-e90a772fd4e5':
      return 'Real life';
    case '37955cd0-4bf8-47ef-b9eb-e90a772fd4e5':
      return 'Khác';
    default:
      return 'id';
  }
};

export const formatFollowers = (followers: number): string => {
  if (followers === 0) return '0';

  const units = ['', 'K', 'M', 'B', 'T'];
  const index = Math.min(Math.floor(Math.log10(Math.abs(followers)) / 3), units.length - 1);
  const scaledFollowers = followers / 1000 ** index;
  const unit = units[index];

  return scaledFollowers >= 1 ? `${Math.round(scaledFollowers)}${unit}` : `${scaledFollowers.toFixed(1)}${unit}`;
};

export const formatCurrencyShortend = (value: number): string => {
  if (value === 0) return '0';
  const units = ['đ', 'đ', 'M', 'B'];
  const index = Math.min(Math.floor(Math.log10(value) / 3), units.length - 1);
  const scaledValue = value / 1000 ** index;
  const formattedValue = scaledValue % 1000 === 0 ? scaledValue.toFixed(3) : scaledValue.toFixed(0).replace('.', ',');
  return `${formattedValue}${units[index]}`;
};

export const formatCurrency = (value: number): string => {
  return `${value.toLocaleString('vi-VN', { style: 'currency', currency: 'VND' })}`;
};
