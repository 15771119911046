import { useCallback, useState } from 'react';
import { postService } from '../../services/post.service';
import { IPostModel } from '../../models';

const useBookmark = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [bookmarkedPosts, setBookmarkedPosts] = useState<IPostModel[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(true);

  const getBookmarkedPosts = useCallback(async (userId: string, pageSize = 10, pageIndex = 1) => {
    setLoading(true);
    try {
      const data = await postService.getBookmark({ userId, pageSize, pageIndex });
      setBookmarkedPosts(data.data);
      setHasMore(data.pagingState !== null);
    } catch (error) {
      console.error('Error fetching bookmarks:', error);
      setBookmarkedPosts([]);
    } finally {
      setLoading(false);
      setHasMore(false);
    }
  }, []);

  const toggleBookmark = useCallback(async (postId: string) => {
    try {
      await postService.toggleBookmark({ postId });
      return true;
    } catch (error) {
      console.error('Error toggling bookmark:', error);
      return false;
    }
  }, []);

  return {
    loading,
    bookmarkedPosts,
    getBookmarkedPosts,
    toggleBookmark,
    hasMore,
  };
};

export default useBookmark;
